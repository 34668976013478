import axios from "axios";

export default function authUser() {
    const http = axios.create({
        baseUrl: "https://bifrust.com",
        headers:{
            "content-Type" : "application/json"
        }
    });
    return {
        http
    }

}