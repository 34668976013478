import React, { useLayoutEffect, useRef } from 'react';
import s from './Icons.module.css';
import { gsap } from "gsap"

const Icons = () => {

	const iconsRef = useRef();

	useLayoutEffect(() => {

		let ctx = gsap.context(() => {

			gsap.set('#iconsSvg', { opacity: 1 })
			gsap.set(`#folders > *, #foldersFinal > *, #files > *,
		#filesFinal > *, #usersYellow > *, #usersBlue > *,#pdfs > *,
		#pdfsFinal > *, #usersYellow2 > *, #usersBlue2 > *`, { opacity: 0 })

			const iconsTl = gsap.timeline({ repeat: -1 })
				.to('#folders > *', { opacity: 1, stagger: 0.5, duration: 1.5, ease: 'sine.Out' })
				.to('#folders > *', { x: 820, y: 850, stagger: 0.5, duration: 1.5, ease: 'sine.Out' }, '<')
				.to('#folders > *', { opacity: 0, scale: 0, stagger: 0.5, duration: 0.8, ease: 'sine.Out' }, '<+0.7')

				.to('#foldersFinal > *', { opacity: 1, stagger: 0.5, duration: 1.5, ease: 'sine.Out' }, '>-2.5')
				.to('#foldersFinal > *', { x: -50, y: -1050, stagger: 0.5, duration: 1.5, ease: 'sine.Out' }, '<')
				.to('#foldersFinal > *', { opacity: 0, scale: 0, stagger: 0.5, duration: 0.8, ease: 'sine.Out' }, '<+0.7')

				.to('#files > *', { opacity: 1, stagger: 0.5, duration: 1.5, ease: 'sine.Out' })
				.to('#files > *', { x: 100, y: 1070, stagger: 0.5, duration: 1.5, ease: 'sine.out' }, '<')
				.to('#files > *', { opacity: 0, scale: 0, stagger: 0.5, duration: 0.8, ease: 'sine.Out' }, '<+0.7')

				.to('#filesFinal > *', { opacity: 1, stagger: 0.5, duration: 1.5, ease: 'sine.Out' }, '>-2.5')
				.to('#filesFinal > *', { x: -720, y: 750, stagger: 0.5, duration: 1.5, ease: 'sine.out' }, '<')
				.to('#filesFinal > *', { opacity: 0, scale: 0, stagger: 0.5, duration: 0.8, ease: 'sine.Out' }, '<+0.65')

				.to('#usersYellow > *', { opacity: 1, stagger: 0.5, duration: 1.5, ease: 'sine.Out' })
				.to('#usersYellow > *', { x: 100, y: -1070, stagger: 0.5, duration: 1.5, ease: 'sine.out' }, '<')
				.to('#usersYellow > *', { opacity: 0, scale: 0, stagger: 0.5, duration: 0.8, ease: 'sine.Out' }, '<+0.7')

				.to('#usersBlue > *', { opacity: 1, stagger: 0.5, duration: 1.5, ease: 'sine.Out' }, '>-2.5')
				.to('#usersBlue > *', { x: -50, y: -1050, stagger: 0.5, duration: 1.5, ease: 'sine.Out' }, '<')
				.to('#usersBlue > *', { opacity: 0, scale: 0, stagger: 0.5, duration: 0.8, ease: 'sine.Out' }, '<+0.7')

				.to('#pdfs > *', { opacity: 1, stagger: 0.5, duration: 1.5, ease: 'sine.Out' })
				.to('#pdfs > *', { x: 800, y: -650, stagger: 0.5, duration: 1.5, ease: 'sine.Out' }, '<')
				.to('#pdfs > *', { opacity: 0, scale: 0, stagger: 0.5, duration: 0.8, ease: 'sine.Out' }, '<+0.7')

				.to('#pdfsFinal > *', { opacity: 1, stagger: 0.5, duration: 1.5, ease: 'sine.Out' }, '>-2.5')
				.to('#pdfsFinal > *', { x: -1000, stagger: 0.5, duration: 1.5, ease: 'sine.Out' }, '<')
				.to('#pdfsFinal > *', { opacity: 0, scale: 0, stagger: 0.5, duration: 0.8, ease: 'sine.Out', transformOrigin: '50% 50%' }, '<+0.7')

				.to('#usersYellow2 > *', { opacity: 1, stagger: 0.5, duration: 1.5, ease: 'sine.Out' })
				.to('#usersYellow2 > *', { x: 800, y: -600, stagger: 0.5, duration: 1.5, ease: 'sine.out' }, '<')
				.to('#usersYellow2 > *', { opacity: 0, scale: 0, stagger: 0.5, duration: 0.8, ease: 'sine.Out' }, '<+0.7')

				.to('#usersBlue2 > *', { opacity: 1, stagger: 0.5, duration: 1.5, ease: 'sine.Out' }, '>-2.5')
				.to('#usersBlue2 > *', { x: -50, y: 1100, stagger: 0.5, duration: 1.5, ease: 'sine.out' }, '<')
				.to('#usersBlue2 > *', { opacity: 0, scale: 0, stagger: 0.5, duration: 0.8, ease: 'sine.Out' }, '<+0.7')
		}, iconsRef)

	}, []);

	return (
		<div className={s.svgWrap} id="iconsSvgWrap" ref={iconsRef}>
			<svg
				data-content="changed"
				preserveAspectRatio=""
				className={s.iconsSvg}
				id="iconsSvg"
				xmlns="http://www.w3.org/2000/svg"
				width={2525.7}
				height={2739.1}
				viewBox="0 0 2525.7 2739.1"
			>
				<g id="foldersFinal">
					<g>
						<path
							d="M1909.93,1324.64h-50.6l-12.6-13.2h-31.6c-7,0-12.6,6-12.6,13.2v79.3c0,7.3,5.7,13.2,12.6,13.2h97.8c5.4,0,9.5-4.3,9.5-9.9v-69.4C1922.53,1330.64,1916.83,1324.64,1909.93,1324.64Z"
							fill="#ffa000"
						/>
						<path
							d="M1923.13,1344.44h-85a12.64,12.64,0,0,0-12.3,11l-10.7,61.8h100.1a12.64,12.64,0,0,0,12.3-11l7.9-46.2C1937,1352.14,1931,1344.44,1923.13,1344.44Z"
							fill="#ffca28"
						/>
					</g>
					<g>
						<path
							d="M1909.83,1324.64h-50.5l-12.6-13.2h-31.6c-7,0-12.6,6-12.6,13.2v79.3c0,7.3,5.7,13.2,12.6,13.2h97.8c5.4,0,9.5-4.3,9.5-9.9v-69.4C1922.43,1330.54,1916.73,1324.64,1909.83,1324.64Z"
							fill="#ffa000"
						/>
						<path
							d="M1923.13,1344.44h-84.9a12.64,12.64,0,0,0-12.3,11l-10.8,61.8h100.1a12.64,12.64,0,0,0,12.3-11l7.9-46.2C1937,1352,1931,1344.44,1923.13,1344.44Z"
							fill="#ffca28"
						/>
					</g>
					<g>
						<path
							d="M1909.88,1324.64h-50.5l-12.6-13.2h-31.6c-7,0-12.6,6-12.6,13.2v79.3c0,7.3,5.7,13.2,12.6,13.2H1913c5.4,0,9.5-4.3,9.5-9.9v-69.4A12.94,12.94,0,0,0,1909.88,1324.64Z"
							fill="#ffa000"
						/>
						<path
							d="M1923.08,1344.44h-84.9a12.64,12.64,0,0,0-12.3,11l-10.7,61.8h100a12.64,12.64,0,0,0,12.3-11l7.9-46.2C1937,1352.14,1931.08,1344.44,1923.08,1344.44Z"
							fill="#ffca28"
						/>
					</g>
					<g>
						<path
							d="M1909.8,1324.64h-50.5l-12.6-13.2h-31.6c-7,0-12.6,6-12.6,13.2v79.3c0,7.3,5.7,13.2,12.6,13.2h97.8c5.4,0,9.5-4.3,9.5-9.9v-69.4A12.88,12.88,0,0,0,1909.8,1324.64Z"
							fill="#ffa000"
						/>
						<path
							d="M1923.1,1344.44h-84.9a12.64,12.64,0,0,0-12.3,11l-10.7,61.8h100.1a12.64,12.64,0,0,0,12.3-11l7.9-46.2C1937,1352.14,1931,1344.44,1923.1,1344.44Z"
							fill="#ffca28"
						/>
					</g>
					<g>
						<path
							d="M1909.85,1324.64h-50.5l-12.6-13.2h-31.6c-7,0-12.6,6-12.6,13.2v79.3c0,7.3,5.7,13.2,12.6,13.2h97.7c5.4,0,9.5-4.3,9.5-9.9v-69.4C1922.45,1330.64,1916.75,1324.64,1909.85,1324.64Z"
							fill="#ffa000"
						/>
						<path
							d="M1923.05,1344.44h-84.9a12.64,12.64,0,0,0-12.3,11l-10.7,61.8h100.1a12.64,12.64,0,0,0,12.3-11l7.9-46.2C1937,1352,1931,1344.44,1923.05,1344.44Z"
							fill="#ffca28"
						/>
					</g>
					<g>
						<path
							d="M1909.85,1324.64h-50.5l-12.6-13.2h-31.6c-7,0-12.6,6-12.6,13.2v79.3c0,7.3,5.7,13.2,12.6,13.2H1913c5.4,0,9.5-4.3,9.5-9.9v-69.4A12.94,12.94,0,0,0,1909.85,1324.64Z"
							fill="#ffa000"
						/>
						<path
							d="M1923.05,1344.44h-84.9a12.64,12.64,0,0,0-12.3,11l-10.7,61.8h100.1a12.64,12.64,0,0,0,12.3-11l7.9-46.2C1937,1352,1931.05,1344.44,1923.05,1344.44Z"
							fill="#ffca28"
						/>
					</g>
				</g>
				<g id="folders">
					<g>
						<path
							d="M1146.19,567.94h-50.6l-12.6-13.2h-31.6c-7,0-12.6,6-12.6,13.2v79.3c0,7.3,5.7,13.2,12.6,13.2h97.8c5.4,0,9.5-4.3,9.5-9.9v-69.4C1158.79,573.94,1153.09,567.94,1146.19,567.94Z"
							fill="#ffa000"
						/>
						<path
							d="M1159.39,587.74h-85a12.64,12.64,0,0,0-12.3,11l-10.7,61.8h100.1a12.64,12.64,0,0,0,12.3-11l7.9-46.2C1173.29,595.44,1167.29,587.74,1159.39,587.74Z"
							fill="#ffca28"
						/>
					</g>
					<g>
						<path
							d="M1146.09,567.94h-50.5l-12.6-13.2h-31.6c-7,0-12.6,6-12.6,13.2v79.3c0,7.3,5.7,13.2,12.6,13.2h97.8c5.4,0,9.5-4.3,9.5-9.9v-69.4C1158.69,573.84,1153,567.94,1146.09,567.94Z"
							fill="#ffa000"
						/>
						<path
							d="M1159.39,587.74h-84.9a12.64,12.64,0,0,0-12.3,11l-10.8,61.8h100.1a12.64,12.64,0,0,0,12.3-11l7.9-46.2C1173.29,595.34,1167.29,587.74,1159.39,587.74Z"
							fill="#ffca28"
						/>
					</g>
					<g>
						<path
							d="M1146.14,567.94h-50.5l-12.6-13.2h-31.6c-7,0-12.6,6-12.6,13.2v79.3c0,7.3,5.7,13.2,12.6,13.2h97.8c5.4,0,9.5-4.3,9.5-9.9v-69.4A12.94,12.94,0,0,0,1146.14,567.94Z"
							fill="#ffa000"
						/>
						<path
							d="M1159.34,587.74h-84.9a12.64,12.64,0,0,0-12.3,11l-10.7,61.8h100a12.64,12.64,0,0,0,12.3-11l7.9-46.2C1173.24,595.44,1167.34,587.74,1159.34,587.74Z"
							fill="#ffca28"
						/>
					</g>
					<g>
						<path
							d="M1146.05,567.94h-50.5l-12.6-13.2h-31.6c-7,0-12.6,6-12.6,13.2v79.3c0,7.3,5.7,13.2,12.6,13.2h97.8c5.4,0,9.5-4.3,9.5-9.9v-69.4A12.88,12.88,0,0,0,1146.05,567.94Z"
							fill="#ffa000"
						/>
						<path
							d="M1159.35,587.74h-84.9a12.64,12.64,0,0,0-12.3,11l-10.7,61.8h100.1a12.64,12.64,0,0,0,12.3-11l7.9-46.2C1173.25,595.44,1167.25,587.74,1159.35,587.74Z"
							fill="#ffca28"
						/>
					</g>
					<g>
						<path
							d="M1146.1,567.94h-50.5l-12.6-13.2h-31.6c-7,0-12.6,6-12.6,13.2v79.3c0,7.3,5.7,13.2,12.6,13.2h97.7c5.4,0,9.5-4.3,9.5-9.9v-69.4C1158.7,573.94,1153,567.94,1146.1,567.94Z"
							fill="#ffa000"
						/>
						<path
							d="M1159.3,587.74h-84.9a12.64,12.64,0,0,0-12.3,11l-10.7,61.8h100.1a12.64,12.64,0,0,0,12.3-11l7.9-46.2C1173.2,595.34,1167.2,587.74,1159.3,587.74Z"
							fill="#ffca28"
						/>
					</g>
					<g>
						<path
							d="M1146.1,567.94h-50.5l-12.6-13.2h-31.6c-7,0-12.6,6-12.6,13.2v79.3c0,7.3,5.7,13.2,12.6,13.2h97.8c5.4,0,9.5-4.3,9.5-9.9v-69.4A12.94,12.94,0,0,0,1146.1,567.94Z"
							fill="#ffa000"
						/>
						<path
							d="M1159.3,587.74h-84.9a12.64,12.64,0,0,0-12.3,11l-10.7,61.8h100.1a12.64,12.64,0,0,0,12.3-11l7.9-46.2C1173.2,595.34,1167.3,587.74,1159.3,587.74Z"
							fill="#ffca28"
						/>
					</g>
				</g>
				<g id="pdfsFinal">
					<g id="pdf1">
						<path
							d="M1881.23,1299.65h-65.3a1.92,1.92,0,0,0-2,1.9v117.5a1.92,1.92,0,0,0,2,1.9h91.2a1.92,1.92,0,0,0,2-1.9v-91.7a4.82,4.82,0,0,0-1.3-3.2l-23.4-23.2A4.22,4.22,0,0,0,1881.23,1299.65Z"
							fill="#fff"
						/>
						<path
							d="M1908.93,1325l-27.3-27.1a5.15,5.15,0,0,0-3.2-1.4h-61.3a4.57,4.57,0,0,0-4.6,4.5v118.3a4.57,4.57,0,0,0,4.6,4.5h88.7a4.57,4.57,0,0,0,4.6-4.5V1328A5.06,5.06,0,0,0,1908.93,1325Zm-29.2-24.7,26.9,26.7h-25.3a1.47,1.47,0,0,1-1.5-1.5l-.1-25.2Zm26,120.7h-88.6a1.47,1.47,0,0,1-1.5-1.5v-118.3a1.54,1.54,0,0,1,1.5-1.5h59.6v25.8a4.57,4.57,0,0,0,4.6,4.5h26v89.5A1.63,1.63,0,0,1,1905.73,1421Z"
							fill="#979593"
						/>
						<path
							d="M1896.53,1343.65h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1896.53,1343.65Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1896.53,1352.65Zm0,9.2h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1896.53,1361.85Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1896.53,1370.85Z"
							fill="#c8c6c4"
						/>
						<path
							d="M1888.93,1383.05l21.3.1a3.1,3.1,0,0,1,3.1,3l-.1,19.6a3,3,0,0,1-3.1,3l-21.3-.1a1.47,1.47,0,0,1-1.5-1.5l.1-22.7A1.52,1.52,0,0,1,1888.93,1383.05Zm-53.5,24.3a1.54,1.54,0,0,1-1.5,1.5l-21.4-.1a3,3,0,0,1-3-3v-19.6a3,3,0,0,1,3.1-3l21.4.1a1.47,1.47,0,0,1,1.5,1.5l-.1,22.6Z"
							fill="#d65532"
						/>
						<path
							d="M1877.43,1409.55h-32.1a3.1,3.1,0,0,1-3.1-3v-21.2a3,3,0,0,1,3.1-3h32.1a3.1,3.1,0,0,1,3.1,3v21.2A3,3,0,0,1,1877.43,1409.55Z"
							fill="none"
							stroke="#d65532"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={3}
						/>
					</g>
					<g id="pdf2">
						<path
							d="M1881.18,1299.55H1816a1.92,1.92,0,0,0-2,1.9V1419a1.92,1.92,0,0,0,2,1.9h91.2a1.92,1.92,0,0,0,2-1.9v-91.7a4.82,4.82,0,0,0-1.3-3.2l-23.4-23.2A5.92,5.92,0,0,0,1881.18,1299.55Z"
							fill="#fff"
						/>
						<path
							d="M1908.88,1325l-27.3-27.1a5.15,5.15,0,0,0-3.2-1.4H1817a4.57,4.57,0,0,0-4.6,4.5v118.3a4.57,4.57,0,0,0,4.6,4.5h88.7a4.57,4.57,0,0,0,4.6-4.5V1328A4.4,4.4,0,0,0,1908.88,1325Zm-29.2-24.7,26.9,26.7h-25.4a1.47,1.47,0,0,1-1.5-1.5v-25.2Zm26,120.7h-88.6a1.47,1.47,0,0,1-1.5-1.5v-118.3a1.54,1.54,0,0,1,1.5-1.5h59.6v25.8a4.57,4.57,0,0,0,4.6,4.5h26v89.5A1.63,1.63,0,0,1,1905.68,1421Z"
							fill="#979593"
						/>
						<path
							d="M1896.48,1343.55h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1896.48,1343.55Zm0,9.2h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1896.48,1352.75Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.37,1.37,0,0,1,1896.48,1361.75Zm0,9.1h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.37,1.37,0,0,1,1896.48,1370.85Z"
							fill="#c8c6c4"
						/>
						<path
							d="M1889,1383.05l21.3.1a3.1,3.1,0,0,1,3.1,3l-.1,19.6a3,3,0,0,1-3.1,3l-21.3-.1a1.47,1.47,0,0,1-1.5-1.5l.1-22.7A1.39,1.39,0,0,1,1889,1383.05Zm-53.6,24.2a1.54,1.54,0,0,1-1.5,1.5l-21.4-.1a3,3,0,0,1-3-3v-19.6a3,3,0,0,1,3.1-3l21.4.1a1.47,1.47,0,0,1,1.5,1.5l-.1,22.6Z"
							fill="#d65532"
						/>
						<path
							d="M1877.48,1409.55h-32.1a3.1,3.1,0,0,1-3.1-3v-21.2a3,3,0,0,1,3.1-3h32.1a3.1,3.1,0,0,1,3.1,3v21.2A3.24,3.24,0,0,1,1877.48,1409.55Z"
							fill="none"
							stroke="#d65532"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={3}
						/>
					</g>
					<g id="pdf3">
						<path
							d="M1881.18,1299.65H1816a1.92,1.92,0,0,0-2,1.9v117.5a1.92,1.92,0,0,0,2,1.9h91.2a1.92,1.92,0,0,0,2-1.9v-91.7a4.82,4.82,0,0,0-1.3-3.2l-23.4-23.2A5.28,5.28,0,0,0,1881.18,1299.65Z"
							fill="#fff"
						/>
						<path
							d="M1909,1325l-27.3-27.1a5.15,5.15,0,0,0-3.2-1.4h-61.4a4.57,4.57,0,0,0-4.6,4.5v118.3a4.57,4.57,0,0,0,4.6,4.5h88.7a4.57,4.57,0,0,0,4.6-4.5V1328A6.24,6.24,0,0,0,1909,1325Zm-29.3-24.6,26.9,26.7h-25.4a1.47,1.47,0,0,1-1.5-1.5Zm26,120.7H1817a1.47,1.47,0,0,1-1.5-1.5v-118.3a1.54,1.54,0,0,1,1.5-1.5h59.6v25.8a4.57,4.57,0,0,0,4.6,4.5h26v89.5A1.54,1.54,0,0,1,1905.68,1421.05Z"
							fill="#979593"
						/>
						<path
							d="M1896.58,1343.65h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.54,1.54,0,0,1,1896.58,1343.65Zm0,9.2h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.54,1.54,0,0,1,1896.58,1352.85Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.54,1.54,0,0,1,1896.58,1361.85Zm0,9.1h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.54,1.54,0,0,1,1896.58,1371Z"
							fill="#c8c6c4"
						/>
						<path
							d="M1889,1383.15l21.3.1a3.1,3.1,0,0,1,3.1,3l-.1,19.6a3,3,0,0,1-3.1,3l-21.3-.1a1.47,1.47,0,0,1-1.5-1.5l.1-22.7C1887.38,1383.75,1888.08,1383.15,1889,1383.15Zm-53.6,24.2a1.54,1.54,0,0,1-1.5,1.5l-21.4-.1a3,3,0,0,1-3-3v-19.6a3,3,0,0,1,3.1-3l21.4.1a1.47,1.47,0,0,1,1.5,1.5l-.1,22.6Z"
							fill="#d65532"
						/>
						<path
							d="M1877.38,1409.65h-32.1a3.1,3.1,0,0,1-3.1-3v-21.2a3,3,0,0,1,3.1-3h32.1a3.1,3.1,0,0,1,3.1,3v21.2A3.1,3.1,0,0,1,1877.38,1409.65Z"
							fill="none"
							stroke="#d65532"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={3}
						/>
					</g>
					<g id="pdf4">
						<path
							d="M1881.18,1299.65h-65.3a1.92,1.92,0,0,0-2,1.9v117.5a1.92,1.92,0,0,0,2,1.9h91.3a1.92,1.92,0,0,0,2-1.9v-91.7a4.82,4.82,0,0,0-1.3-3.2l-23.4-23.2A5.51,5.51,0,0,0,1881.18,1299.65Z"
							fill="#fff"
						/>
						<path
							d="M1908.88,1325l-27.3-27.1a5.15,5.15,0,0,0-3.2-1.4h-61.3a4.57,4.57,0,0,0-4.6,4.5v118.3a4.57,4.57,0,0,0,4.6,4.5h88.6a4.57,4.57,0,0,0,4.6-4.5V1328A5.85,5.85,0,0,0,1908.88,1325Zm-29.2-24.7,26.9,26.7h-25.4a1.47,1.47,0,0,1-1.5-1.5v-25.2Zm26,120.8H1817a1.47,1.47,0,0,1-1.5-1.5v-118.3a1.54,1.54,0,0,1,1.5-1.5h59.6v25.8a4.57,4.57,0,0,0,4.6,4.5h26v89.5A1.54,1.54,0,0,1,1905.68,1421.05Z"
							fill="#979593"
						/>
						<path
							d="M1896.48,1343.65h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1896.48,1343.65Zm0,9.1h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.37,1.37,0,0,1,1896.48,1352.75Zm0,9.1h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1896.48,1361.85Zm0,9.1h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1896.48,1371Z"
							fill="#c8c6c4"
						/>
						<path
							d="M1889,1383.15l21.3.1a3.1,3.1,0,0,1,3.1,3l-.1,19.6a3,3,0,0,1-3.1,3l-21.3-.1a1.47,1.47,0,0,1-1.5-1.5l.1-22.7C1887.38,1383.75,1888.08,1383.15,1889,1383.15Zm-53.6,24.2a1.54,1.54,0,0,1-1.5,1.5l-21.4-.1a3,3,0,0,1-3-3v-19.6a3,3,0,0,1,3.1-3l21.4.1a1.47,1.47,0,0,1,1.5,1.5l-.1,22.6Z"
							fill="#d65532"
						/>
						<path
							d="M1877.38,1409.65h-32.1a3.1,3.1,0,0,1-3.1-3v-21.2a3,3,0,0,1,3.1-3h32.1a3.1,3.1,0,0,1,3.1,3v21.2A3.17,3.17,0,0,1,1877.38,1409.65Z"
							fill="none"
							stroke="#d65532"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={3}
						/>
					</g>
					<g id="pdf5">
						<path
							d="M1881.23,1299.65H1816a1.92,1.92,0,0,0-2,1.9v117.5a1.92,1.92,0,0,0,2,1.9h91.2a1.92,1.92,0,0,0,2-1.9v-91.7a4.82,4.82,0,0,0-1.3-3.2l-23.4-23.2A4.77,4.77,0,0,0,1881.23,1299.65Z"
							fill="#fff"
						/>
						<path
							d="M1908.93,1325l-27.3-27.1a5.15,5.15,0,0,0-3.2-1.4H1817a4.57,4.57,0,0,0-4.6,4.5v118.3a4.57,4.57,0,0,0,4.6,4.5h88.7a4.57,4.57,0,0,0,4.6-4.5V1328A4.4,4.4,0,0,0,1908.93,1325Zm-29.2-24.7,26.9,26.7h-25.4a1.47,1.47,0,0,1-1.5-1.5Zm26,120.7h-88.6a1.47,1.47,0,0,1-1.5-1.5v-118.3a1.54,1.54,0,0,1,1.5-1.5h59.6v25.8a4.57,4.57,0,0,0,4.6,4.5h26v89.5A1.63,1.63,0,0,1,1905.73,1421Z"
							fill="#979593"
						/>
						<path
							d="M1896.53,1343.65h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1896.53,1343.65Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1896.53,1352.65Zm0,9.2h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1896.53,1361.85Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1896.53,1370.85Z"
							fill="#c8c6c4"
						/>
						<path
							d="M1888.93,1383.05l21.3.1a3.1,3.1,0,0,1,3.1,3l-.1,19.6a3,3,0,0,1-3.1,3l-21.3-.1a1.47,1.47,0,0,1-1.5-1.5l.1-22.7A1.52,1.52,0,0,1,1888.93,1383.05Zm-53.5,24.3a1.54,1.54,0,0,1-1.5,1.5l-21.4-.1a3,3,0,0,1-3-3v-19.6a3,3,0,0,1,3.1-3l21.4.1a1.47,1.47,0,0,1,1.5,1.5l-.1,22.6Z"
							fill="#d65532"
						/>
						<path
							d="M1877.43,1409.55h-32.1a3.1,3.1,0,0,1-3.1-3v-21.2a3,3,0,0,1,3.1-3h32.1a3.1,3.1,0,0,1,3.1,3v21.2A3,3,0,0,1,1877.43,1409.55Z"
							fill="none"
							stroke="#d65532"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={3}
						/>
					</g>
					<g id="pdf6">
						<path
							d="M1881.23,1299.65H1816a1.92,1.92,0,0,0-2,1.9v117.5a1.92,1.92,0,0,0,2,1.9h91.2a1.92,1.92,0,0,0,2-1.9v-91.7a4.82,4.82,0,0,0-1.3-3.2l-23.4-23.2A4.6,4.6,0,0,0,1881.23,1299.65Z"
							fill="#fff"
						/>
						<path
							d="M1908.93,1325l-27.3-27.1a5.15,5.15,0,0,0-3.2-1.4H1817a4.57,4.57,0,0,0-4.6,4.5v118.3a4.57,4.57,0,0,0,4.6,4.5h88.7a4.57,4.57,0,0,0,4.6-4.5V1328A4.4,4.4,0,0,0,1908.93,1325Zm-29.2-24.7,26.9,26.7h-25.4a1.47,1.47,0,0,1-1.5-1.5Zm26,120.7H1817a1.47,1.47,0,0,1-1.5-1.5v-118.3a1.54,1.54,0,0,1,1.5-1.5h59.7v25.8a4.57,4.57,0,0,0,4.6,4.5h26v89.5A1.63,1.63,0,0,1,1905.73,1421Z"
							fill="#979593"
						/>
						<path
							d="M1896.53,1343.65h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1896.53,1343.65Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1896.53,1352.65Zm0,9.2h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1896.53,1361.85Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1896.53,1370.85Z"
							fill="#c8c6c4"
						/>
						<path
							d="M1888.93,1383.05l21.3.1a3.1,3.1,0,0,1,3.1,3l-.1,19.6a3,3,0,0,1-3.1,3l-21.3-.1a1.47,1.47,0,0,1-1.5-1.5l.1-22.7A1.52,1.52,0,0,1,1888.93,1383.05Zm-53.5,24.3a1.54,1.54,0,0,1-1.5,1.5l-21.4-.1a3,3,0,0,1-3-3v-19.6a3,3,0,0,1,3.1-3l21.4.1a1.47,1.47,0,0,1,1.5,1.5l-.1,22.6Z"
							fill="#d65532"
						/>
						<path
							d="M1877.43,1409.55h-32.1a3.1,3.1,0,0,1-3.1-3v-21.2a3,3,0,0,1,3.1-3h32.1a3.1,3.1,0,0,1,3.1,3v21.2A3,3,0,0,1,1877.43,1409.55Z"
							fill="none"
							stroke="#d65532"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={3}
						/>
					</g>
				</g>
				<g id="pdfs">
					<g id="pdf1-2" data-name="pdf1">
						<path
							d="M1099.64,1970.7h-65.3a1.92,1.92,0,0,0-2,1.9v117.5a1.92,1.92,0,0,0,2,1.9h91.2a1.92,1.92,0,0,0,2-1.9v-91.7a4.82,4.82,0,0,0-1.3-3.2l-23.4-23.2A4.22,4.22,0,0,0,1099.64,1970.7Z"
							fill="#fff"
						/>
						<path
							d="M1127.34,1996l-27.3-27.1a5.15,5.15,0,0,0-3.2-1.4h-61.3a4.57,4.57,0,0,0-4.6,4.5v118.3a4.57,4.57,0,0,0,4.6,4.5h88.7a4.57,4.57,0,0,0,4.6-4.5V1999A5.06,5.06,0,0,0,1127.34,1996Zm-29.2-24.7L1125,1998h-25.3a1.47,1.47,0,0,1-1.5-1.5l-.1-25.2Zm26,120.7h-88.6a1.47,1.47,0,0,1-1.5-1.5V1972.2a1.54,1.54,0,0,1,1.5-1.5h59.6v25.8a4.57,4.57,0,0,0,4.6,4.5h26v89.5A1.63,1.63,0,0,1,1124.14,2092Z"
							fill="#979593"
						/>
						<path
							d="M1114.94,2014.7h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1114.94,2014.7Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1114.94,2023.7Zm0,9.2h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1114.94,2032.9Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1114.94,2041.9Z"
							fill="#c8c6c4"
						/>
						<path
							d="M1107.34,2054.1l21.3.1a3.1,3.1,0,0,1,3.1,3l-.1,19.6a3,3,0,0,1-3.1,3l-21.3-.1a1.47,1.47,0,0,1-1.5-1.5l.1-22.7A1.52,1.52,0,0,1,1107.34,2054.1Zm-53.5,24.3a1.54,1.54,0,0,1-1.5,1.5l-21.4-.1a3,3,0,0,1-3-3v-19.6a3,3,0,0,1,3.1-3l21.4.1a1.47,1.47,0,0,1,1.5,1.5l-.1,22.6Z"
							fill="#d65532"
						/>
						<path
							d="M1095.84,2080.6h-32.1a3.1,3.1,0,0,1-3.1-3v-21.2a3,3,0,0,1,3.1-3h32.1a3.1,3.1,0,0,1,3.1,3v21.2A3,3,0,0,1,1095.84,2080.6Z"
							fill="none"
							stroke="#d65532"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={3}
						/>
					</g>
					<g id="pdf2-2" data-name="pdf2">
						<path
							d="M1099.59,1970.6h-65.2a1.92,1.92,0,0,0-2,1.9V2090a1.92,1.92,0,0,0,2,1.9h91.2a1.92,1.92,0,0,0,2-1.9v-91.7a4.82,4.82,0,0,0-1.3-3.2l-23.4-23.2A5.92,5.92,0,0,0,1099.59,1970.6Z"
							fill="#fff"
						/>
						<path
							d="M1127.29,1996l-27.3-27.1a5.15,5.15,0,0,0-3.2-1.4h-61.4a4.57,4.57,0,0,0-4.6,4.5v118.3a4.57,4.57,0,0,0,4.6,4.5h88.7a4.57,4.57,0,0,0,4.6-4.5V1999A4.4,4.4,0,0,0,1127.29,1996Zm-29.2-24.7L1125,1998h-25.4a1.47,1.47,0,0,1-1.5-1.5v-25.2Zm26,120.7h-88.6a1.47,1.47,0,0,1-1.5-1.5V1972.2a1.54,1.54,0,0,1,1.5-1.5h59.6v25.8a4.57,4.57,0,0,0,4.6,4.5h26v89.5A1.63,1.63,0,0,1,1124.09,2092Z"
							fill="#979593"
						/>
						<path
							d="M1114.89,2014.6h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1114.89,2014.6Zm0,9.2h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1114.89,2023.8Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.37,1.37,0,0,1,1114.89,2032.8Zm0,9.1h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.37,1.37,0,0,1,1114.89,2041.9Z"
							fill="#c8c6c4"
						/>
						<path
							d="M1107.39,2054.1l21.3.1a3.1,3.1,0,0,1,3.1,3l-.1,19.6a3,3,0,0,1-3.1,3l-21.3-.1a1.47,1.47,0,0,1-1.5-1.5l.1-22.7A1.39,1.39,0,0,1,1107.39,2054.1Zm-53.6,24.2a1.54,1.54,0,0,1-1.5,1.5l-21.4-.1a3,3,0,0,1-3-3v-19.6a3,3,0,0,1,3.1-3l21.4.1a1.47,1.47,0,0,1,1.5,1.5l-.1,22.6Z"
							fill="#d65532"
						/>
						<path
							d="M1095.89,2080.6h-32.1a3.1,3.1,0,0,1-3.1-3v-21.2a3,3,0,0,1,3.1-3h32.1a3.1,3.1,0,0,1,3.1,3v21.2A3.24,3.24,0,0,1,1095.89,2080.6Z"
							fill="none"
							stroke="#d65532"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={3}
						/>
					</g>
					<g id="pdf3-2" data-name="pdf3">
						<path
							d="M1099.59,1970.7h-65.2a1.92,1.92,0,0,0-2,1.9v117.5a1.92,1.92,0,0,0,2,1.9h91.2a1.92,1.92,0,0,0,2-1.9v-91.7a4.82,4.82,0,0,0-1.3-3.2l-23.4-23.2A5.28,5.28,0,0,0,1099.59,1970.7Z"
							fill="#fff"
						/>
						<path
							d="M1127.39,1996l-27.3-27.1a5.15,5.15,0,0,0-3.2-1.4h-61.4a4.57,4.57,0,0,0-4.6,4.5v118.3a4.57,4.57,0,0,0,4.6,4.5h88.7a4.57,4.57,0,0,0,4.6-4.5V1999A6.24,6.24,0,0,0,1127.39,1996Zm-29.3-24.6,26.9,26.7h-25.4a1.47,1.47,0,0,1-1.5-1.5Zm26,120.7h-88.7a1.47,1.47,0,0,1-1.5-1.5V1972.3a1.54,1.54,0,0,1,1.5-1.5H1095v25.8a4.57,4.57,0,0,0,4.6,4.5h26v89.5A1.54,1.54,0,0,1,1124.09,2092.1Z"
							fill="#979593"
						/>
						<path
							d="M1115,2014.7h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5H1115a1.47,1.47,0,0,1,1.5,1.5h0A1.54,1.54,0,0,1,1115,2014.7Zm0,9.2h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5H1115a1.47,1.47,0,0,1,1.5,1.5h0A1.54,1.54,0,0,1,1115,2023.9Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5H1115a1.47,1.47,0,0,1,1.5,1.5h0A1.54,1.54,0,0,1,1115,2032.9Zm0,9.1h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5H1115a1.47,1.47,0,0,1,1.5,1.5h0A1.54,1.54,0,0,1,1115,2042Z"
							fill="#c8c6c4"
						/>
						<path
							d="M1107.39,2054.2l21.3.1a3.1,3.1,0,0,1,3.1,3l-.1,19.6a3,3,0,0,1-3.1,3l-21.3-.1a1.47,1.47,0,0,1-1.5-1.5l.1-22.7C1105.79,2054.8,1106.49,2054.2,1107.39,2054.2Zm-53.6,24.2a1.54,1.54,0,0,1-1.5,1.5l-21.4-.1a3,3,0,0,1-3-3v-19.6a3,3,0,0,1,3.1-3l21.4.1a1.47,1.47,0,0,1,1.5,1.5l-.1,22.6Z"
							fill="#d65532"
						/>
						<path
							d="M1095.79,2080.7h-32.1a3.1,3.1,0,0,1-3.1-3v-21.2a3,3,0,0,1,3.1-3h32.1a3.1,3.1,0,0,1,3.1,3v21.2A3.1,3.1,0,0,1,1095.79,2080.7Z"
							fill="none"
							stroke="#d65532"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={3}
						/>
					</g>
					<g id="pdf4-2" data-name="pdf4">
						<path
							d="M1099.59,1970.7h-65.3a1.92,1.92,0,0,0-2,1.9v117.5a1.92,1.92,0,0,0,2,1.9h91.3a1.92,1.92,0,0,0,2-1.9v-91.7a4.82,4.82,0,0,0-1.3-3.2l-23.4-23.2A5.51,5.51,0,0,0,1099.59,1970.7Z"
							fill="#fff"
						/>
						<path
							d="M1127.29,1996l-27.3-27.1a5.15,5.15,0,0,0-3.2-1.4h-61.3a4.57,4.57,0,0,0-4.6,4.5v118.3a4.57,4.57,0,0,0,4.6,4.5h88.6a4.57,4.57,0,0,0,4.6-4.5V1999A5.85,5.85,0,0,0,1127.29,1996Zm-29.2-24.7L1125,1998h-25.4a1.47,1.47,0,0,1-1.5-1.5v-25.2Zm26,120.8h-88.7a1.47,1.47,0,0,1-1.5-1.5V1972.3a1.54,1.54,0,0,1,1.5-1.5H1095v25.8a4.57,4.57,0,0,0,4.6,4.5h26v89.5A1.54,1.54,0,0,1,1124.09,2092.1Z"
							fill="#979593"
						/>
						<path
							d="M1114.89,2014.7h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1114.89,2014.7Zm0,9.1h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.37,1.37,0,0,1,1114.89,2023.8Zm0,9.1h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1114.89,2032.9Zm0,9.1h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1114.89,2042Z"
							fill="#c8c6c4"
						/>
						<path
							d="M1107.39,2054.2l21.3.1a3.1,3.1,0,0,1,3.1,3l-.1,19.6a3,3,0,0,1-3.1,3l-21.3-.1a1.47,1.47,0,0,1-1.5-1.5l.1-22.7C1105.79,2054.8,1106.49,2054.2,1107.39,2054.2Zm-53.6,24.2a1.54,1.54,0,0,1-1.5,1.5l-21.4-.1a3,3,0,0,1-3-3v-19.6a3,3,0,0,1,3.1-3l21.4.1a1.47,1.47,0,0,1,1.5,1.5l-.1,22.6Z"
							fill="#d65532"
						/>
						<path
							d="M1095.79,2080.7h-32.1a3.1,3.1,0,0,1-3.1-3v-21.2a3,3,0,0,1,3.1-3h32.1a3.1,3.1,0,0,1,3.1,3v21.2A3.17,3.17,0,0,1,1095.79,2080.7Z"
							fill="none"
							stroke="#d65532"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={3}
						/>
					</g>
					<g id="pdf5-2" data-name="pdf5">
						<path
							d="M1099.64,1970.7h-65.2a1.92,1.92,0,0,0-2,1.9v117.5a1.92,1.92,0,0,0,2,1.9h91.2a1.92,1.92,0,0,0,2-1.9v-91.7a4.82,4.82,0,0,0-1.3-3.2l-23.4-23.2A4.77,4.77,0,0,0,1099.64,1970.7Z"
							fill="#fff"
						/>
						<path
							d="M1127.34,1996l-27.3-27.1a5.15,5.15,0,0,0-3.2-1.4h-61.4a4.57,4.57,0,0,0-4.6,4.5v118.3a4.57,4.57,0,0,0,4.6,4.5h88.7a4.57,4.57,0,0,0,4.6-4.5V1999A4.4,4.4,0,0,0,1127.34,1996Zm-29.2-24.7L1125,1998h-25.4a1.47,1.47,0,0,1-1.5-1.5Zm26,120.7h-88.6a1.47,1.47,0,0,1-1.5-1.5V1972.2a1.54,1.54,0,0,1,1.5-1.5h59.6v25.8a4.57,4.57,0,0,0,4.6,4.5h26v89.5A1.63,1.63,0,0,1,1124.14,2092Z"
							fill="#979593"
						/>
						<path
							d="M1114.94,2014.7h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1114.94,2014.7Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1114.94,2023.7Zm0,9.2h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1114.94,2032.9Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1114.94,2041.9Z"
							fill="#c8c6c4"
						/>
						<path
							d="M1107.34,2054.1l21.3.1a3.1,3.1,0,0,1,3.1,3l-.1,19.6a3,3,0,0,1-3.1,3l-21.3-.1a1.47,1.47,0,0,1-1.5-1.5l.1-22.7A1.52,1.52,0,0,1,1107.34,2054.1Zm-53.5,24.3a1.54,1.54,0,0,1-1.5,1.5l-21.4-.1a3,3,0,0,1-3-3v-19.6a3,3,0,0,1,3.1-3l21.4.1a1.47,1.47,0,0,1,1.5,1.5l-.1,22.6Z"
							fill="#d65532"
						/>
						<path
							d="M1095.84,2080.6h-32.1a3.1,3.1,0,0,1-3.1-3v-21.2a3,3,0,0,1,3.1-3h32.1a3.1,3.1,0,0,1,3.1,3v21.2A3,3,0,0,1,1095.84,2080.6Z"
							fill="none"
							stroke="#d65532"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={3}
						/>
					</g>
					<g id="pdf6-2" data-name="pdf6">
						<path
							d="M1099.64,1970.7h-65.2a1.92,1.92,0,0,0-2,1.9v117.5a1.92,1.92,0,0,0,2,1.9h91.2a1.92,1.92,0,0,0,2-1.9v-91.7a4.82,4.82,0,0,0-1.3-3.2l-23.4-23.2A4.6,4.6,0,0,0,1099.64,1970.7Z"
							fill="#fff"
						/>
						<path
							d="M1127.34,1996l-27.3-27.1a5.15,5.15,0,0,0-3.2-1.4h-61.4a4.57,4.57,0,0,0-4.6,4.5v118.3a4.57,4.57,0,0,0,4.6,4.5h88.7a4.57,4.57,0,0,0,4.6-4.5V1999A4.4,4.4,0,0,0,1127.34,1996Zm-29.2-24.7L1125,1998h-25.4a1.47,1.47,0,0,1-1.5-1.5Zm26,120.7h-88.7a1.47,1.47,0,0,1-1.5-1.5V1972.2a1.54,1.54,0,0,1,1.5-1.5h59.7v25.8a4.57,4.57,0,0,0,4.6,4.5h26v89.5A1.63,1.63,0,0,1,1124.14,2092Z"
							fill="#979593"
						/>
						<path
							d="M1114.94,2014.7h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1114.94,2014.7Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1114.94,2023.7Zm0,9.2h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1114.94,2032.9Zm0,9h-70.3a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.3a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1114.94,2041.9Z"
							fill="#c8c6c4"
						/>
						<path
							d="M1107.34,2054.1l21.3.1a3.1,3.1,0,0,1,3.1,3l-.1,19.6a3,3,0,0,1-3.1,3l-21.3-.1a1.47,1.47,0,0,1-1.5-1.5l.1-22.7A1.52,1.52,0,0,1,1107.34,2054.1Zm-53.5,24.3a1.54,1.54,0,0,1-1.5,1.5l-21.4-.1a3,3,0,0,1-3-3v-19.6a3,3,0,0,1,3.1-3l21.4.1a1.47,1.47,0,0,1,1.5,1.5l-.1,22.6Z"
							fill="#d65532"
						/>
						<path
							d="M1095.84,2080.6h-32.1a3.1,3.1,0,0,1-3.1-3v-21.2a3,3,0,0,1,3.1-3h32.1a3.1,3.1,0,0,1,3.1,3v21.2A3,3,0,0,1,1095.84,2080.6Z"
							fill="none"
							stroke="#d65532"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={3}
						/>
					</g>
				</g>
				<g id="usersYellow2">
					<g>
						<path
							d="M1152.06,2084.33a4.2,4.2,0,0,1-1.55,1.58,4.13,4.13,0,0,1-2.14.56h-103.9a4.13,4.13,0,0,1-2.14-.56,4.22,4.22,0,0,1-1.56-1.58,4.13,4.13,0,0,1,0-4.29,64.65,64.65,0,0,1,35.94-29,38.55,38.55,0,1,1,39.42,0,64.63,64.63,0,0,1,35.93,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#ffba00"
							stroke="#ffba00"
							strokeMiterlimit={10}
						/>
						<path
							d="M1141,2078.25h-89.13a56.45,56.45,0,0,1,27.4-19.41l17.17-5.57,17.16,5.57A56.36,56.36,0,0,1,1141,2078.25Zm-29.06-34.3-15.5,9.22-15.51-9.22a30.33,30.33,0,1,1,31,0Z"
							fill="#ffba00"
							stroke="#ffba00"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1152.06,2084.33a4.2,4.2,0,0,1-1.55,1.58,4.13,4.13,0,0,1-2.14.56h-103.9a4.13,4.13,0,0,1-2.14-.56,4.22,4.22,0,0,1-1.56-1.58,4.13,4.13,0,0,1,0-4.29,64.65,64.65,0,0,1,35.94-29,38.55,38.55,0,1,1,39.42,0,64.63,64.63,0,0,1,35.93,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#ffba00"
							stroke="#ffba00"
							strokeMiterlimit={10}
						/>
						<path
							d="M1141,2078.25h-89.13a56.45,56.45,0,0,1,27.4-19.41l17.17-5.57,17.16,5.57A56.36,56.36,0,0,1,1141,2078.25Zm-29.06-34.3-15.5,9.22-15.51-9.22a30.33,30.33,0,1,1,31,0Z"
							fill="#ffba00"
							stroke="#ffba00"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1152.06,2084.33a4.2,4.2,0,0,1-1.55,1.58,4.13,4.13,0,0,1-2.14.56h-103.9a4.13,4.13,0,0,1-2.14-.56,4.22,4.22,0,0,1-1.56-1.58,4.13,4.13,0,0,1,0-4.29,64.65,64.65,0,0,1,35.94-29,38.55,38.55,0,1,1,39.42,0,64.63,64.63,0,0,1,35.93,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#ffba00"
							stroke="#ffba00"
							strokeMiterlimit={10}
						/>
						<path
							d="M1141,2078.25h-89.13a56.45,56.45,0,0,1,27.4-19.41l17.17-5.57,17.16,5.57A56.36,56.36,0,0,1,1141,2078.25Zm-29.06-34.3-15.5,9.22-15.51-9.22a30.33,30.33,0,1,1,31,0Z"
							fill="#ffba00"
							stroke="#ffba00"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1152.06,2084.33a4.2,4.2,0,0,1-1.55,1.58,4.13,4.13,0,0,1-2.14.56h-103.9a4.13,4.13,0,0,1-2.14-.56,4.22,4.22,0,0,1-1.56-1.58,4.13,4.13,0,0,1,0-4.29,64.65,64.65,0,0,1,35.94-29,38.55,38.55,0,1,1,39.42,0,64.63,64.63,0,0,1,35.93,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#ffba00"
							stroke="#ffba00"
							strokeMiterlimit={10}
						/>
						<path
							d="M1141,2078.25h-89.13a56.45,56.45,0,0,1,27.4-19.41l17.17-5.57,17.16,5.57A56.36,56.36,0,0,1,1141,2078.25Zm-29.06-34.3-15.5,9.22-15.51-9.22a30.33,30.33,0,1,1,31,0Z"
							fill="#ffba00"
							stroke="#ffba00"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1152.06,2084.33a4.2,4.2,0,0,1-1.55,1.58,4.13,4.13,0,0,1-2.14.56h-103.9a4.13,4.13,0,0,1-2.14-.56,4.22,4.22,0,0,1-1.56-1.58,4.13,4.13,0,0,1,0-4.29,64.65,64.65,0,0,1,35.94-29,38.55,38.55,0,1,1,39.42,0,64.63,64.63,0,0,1,35.93,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#ffba00"
							stroke="#ffba00"
							strokeMiterlimit={10}
						/>
						<path
							d="M1141,2078.25h-89.13a56.45,56.45,0,0,1,27.4-19.41l17.17-5.57,17.16,5.57A56.36,56.36,0,0,1,1141,2078.25Zm-29.06-34.3-15.5,9.22-15.51-9.22a30.33,30.33,0,1,1,31,0Z"
							fill="#ffba00"
							stroke="#ffba00"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1152.06,2084.33a4.2,4.2,0,0,1-1.55,1.58,4.13,4.13,0,0,1-2.14.56h-103.9a4.13,4.13,0,0,1-2.14-.56,4.22,4.22,0,0,1-1.56-1.58,4.13,4.13,0,0,1,0-4.29,64.65,64.65,0,0,1,35.94-29,38.55,38.55,0,1,1,39.42,0,64.63,64.63,0,0,1,35.93,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#ffba00"
							stroke="#ffba00"
							strokeMiterlimit={10}
						/>
						<path
							d="M1141,2078.25h-89.13a56.45,56.45,0,0,1,27.4-19.41l17.17-5.57,17.16,5.57A56.36,56.36,0,0,1,1141,2078.25Zm-29.06-34.3-15.5,9.22-15.51-9.22a30.33,30.33,0,1,1,31,0Z"
							fill="#ffba00"
							stroke="#ffba00"
							strokeWidth={6}
						/>
					</g>
				</g>
				<g id="usersYellow">
					<g>
						<path
							d="M1862.12,2462.48a4.16,4.16,0,0,1-3.69,2.14h-103.9a4.13,4.13,0,0,1-2.14-.56,4.22,4.22,0,0,1-1.56-1.58,4.12,4.12,0,0,1,0-4.28,64.67,64.67,0,0,1,35.94-29,38.55,38.55,0,1,1,39.42,0,64.65,64.65,0,0,1,35.93,29,4.12,4.12,0,0,1,0,4.28Z"
							fill="#28a8ea"
							stroke="#28a8ea"
							strokeMiterlimit={10}
						/>
						<path
							d="M1851,2456.4h-89.13a56.45,56.45,0,0,1,27.4-19.41l17.17-5.57,17.16,5.57A56.36,56.36,0,0,1,1851,2456.4ZM1822,2422.1l-15.5,9.22L1791,2422.1a30.33,30.33,0,1,1,31,0Z"
							fill="#28a8ea"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1862.12,2462.48a4.16,4.16,0,0,1-3.69,2.14h-103.9a4.13,4.13,0,0,1-2.14-.56,4.22,4.22,0,0,1-1.56-1.58,4.12,4.12,0,0,1,0-4.28,64.67,64.67,0,0,1,35.94-29,38.55,38.55,0,1,1,39.42,0,64.65,64.65,0,0,1,35.93,29,4.12,4.12,0,0,1,0,4.28Z"
							fill="#28a8ea"
							stroke="#28a8ea"
							strokeMiterlimit={10}
						/>
						<path
							d="M1851,2456.4h-89.13a56.45,56.45,0,0,1,27.4-19.41l17.17-5.57,17.16,5.57A56.36,56.36,0,0,1,1851,2456.4ZM1822,2422.1l-15.5,9.22L1791,2422.1a30.33,30.33,0,1,1,31,0Z"
							fill="#28a8ea"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1862.12,2462.48a4.16,4.16,0,0,1-3.69,2.14h-103.9a4.13,4.13,0,0,1-2.14-.56,4.22,4.22,0,0,1-1.56-1.58,4.12,4.12,0,0,1,0-4.28,64.67,64.67,0,0,1,35.94-29,38.55,38.55,0,1,1,39.42,0,64.65,64.65,0,0,1,35.93,29,4.12,4.12,0,0,1,0,4.28Z"
							fill="#28a8ea"
							stroke="#28a8ea"
							strokeMiterlimit={10}
						/>
						<path
							d="M1851,2456.4h-89.13a56.45,56.45,0,0,1,27.4-19.41l17.17-5.57,17.16,5.57A56.36,56.36,0,0,1,1851,2456.4ZM1822,2422.1l-15.5,9.22L1791,2422.1a30.33,30.33,0,1,1,31,0Z"
							fill="#28a8ea"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1862.12,2462.48a4.16,4.16,0,0,1-3.69,2.14h-103.9a4.13,4.13,0,0,1-2.14-.56,4.22,4.22,0,0,1-1.56-1.58,4.12,4.12,0,0,1,0-4.28,64.67,64.67,0,0,1,35.94-29,38.55,38.55,0,1,1,39.42,0,64.65,64.65,0,0,1,35.93,29,4.12,4.12,0,0,1,0,4.28Z"
							fill="#28a8ea"
							stroke="#28a8ea"
							strokeMiterlimit={10}
						/>
						<path
							d="M1851,2456.4h-89.13a56.45,56.45,0,0,1,27.4-19.41l17.17-5.57,17.16,5.57A56.36,56.36,0,0,1,1851,2456.4ZM1822,2422.1l-15.5,9.22L1791,2422.1a30.33,30.33,0,1,1,31,0Z"
							fill="#28a8ea"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1862.12,2462.48a4.16,4.16,0,0,1-3.69,2.14h-103.9a4.13,4.13,0,0,1-2.14-.56,4.22,4.22,0,0,1-1.56-1.58,4.12,4.12,0,0,1,0-4.28,64.67,64.67,0,0,1,35.94-29,38.55,38.55,0,1,1,39.42,0,64.65,64.65,0,0,1,35.93,29,4.12,4.12,0,0,1,0,4.28Z"
							fill="#28a8ea"
							stroke="#28a8ea"
							strokeMiterlimit={10}
						/>
						<path
							d="M1851,2456.4h-89.13a56.45,56.45,0,0,1,27.4-19.41l17.17-5.57,17.16,5.57A56.36,56.36,0,0,1,1851,2456.4ZM1822,2422.1l-15.5,9.22L1791,2422.1a30.33,30.33,0,1,1,31,0Z"
							fill="#28a8ea"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1862.12,2462.48a4.16,4.16,0,0,1-3.69,2.14h-103.9a4.13,4.13,0,0,1-2.14-.56,4.22,4.22,0,0,1-1.56-1.58,4.12,4.12,0,0,1,0-4.28,64.67,64.67,0,0,1,35.94-29,38.55,38.55,0,1,1,39.42,0,64.65,64.65,0,0,1,35.93,29,4.12,4.12,0,0,1,0,4.28Z"
							fill="#28a8ea"
							stroke="#28a8ea"
							strokeMiterlimit={10}
						/>
						<path
							d="M1851,2456.4h-89.13a56.45,56.45,0,0,1,27.4-19.41l17.17-5.57,17.16,5.57A56.36,56.36,0,0,1,1851,2456.4ZM1822,2422.1l-15.5,9.22L1791,2422.1a30.33,30.33,0,1,1,31,0Z"
							fill="#28a8ea"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
				</g>
				<g id="usersBlue2">
					<g>
						<path
							d="M1899.68,1414.91a4.3,4.3,0,0,1-1.56,1.58,4.22,4.22,0,0,1-2.14.56h-103.9a4.22,4.22,0,0,1-2.14-.56,4.27,4.27,0,0,1-1.55-1.58,4.13,4.13,0,0,1,0-4.29,64.63,64.63,0,0,1,35.93-29,38.56,38.56,0,1,1,39.42,0,64.65,64.65,0,0,1,35.94,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#28a8ea"
						/>
						<path
							d="M1888.6,1408.82h-89.13a56.34,56.34,0,0,1,27.4-19.4l17.16-5.58,17.17,5.58A56.43,56.43,0,0,1,1888.6,1408.82Zm-29.06-34.29-15.51,9.21-15.5-9.21a30.33,30.33,0,1,1,31,0Z"
							fill="none"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1899.68,1414.91a4.3,4.3,0,0,1-1.56,1.58,4.22,4.22,0,0,1-2.14.56h-103.9a4.22,4.22,0,0,1-2.14-.56,4.27,4.27,0,0,1-1.55-1.58,4.13,4.13,0,0,1,0-4.29,64.63,64.63,0,0,1,35.93-29,38.56,38.56,0,1,1,39.42,0,64.65,64.65,0,0,1,35.94,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#28a8ea"
						/>
						<path
							d="M1888.6,1408.82h-89.13a56.34,56.34,0,0,1,27.4-19.4l17.16-5.58,17.17,5.58A56.43,56.43,0,0,1,1888.6,1408.82Zm-29.06-34.29-15.51,9.21-15.5-9.21a30.33,30.33,0,1,1,31,0Z"
							fill="none"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1899.68,1414.91a4.3,4.3,0,0,1-1.56,1.58,4.22,4.22,0,0,1-2.14.56h-103.9a4.22,4.22,0,0,1-2.14-.56,4.27,4.27,0,0,1-1.55-1.58,4.13,4.13,0,0,1,0-4.29,64.63,64.63,0,0,1,35.93-29,38.56,38.56,0,1,1,39.42,0,64.65,64.65,0,0,1,35.94,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#28a8ea"
						/>
						<path
							d="M1888.6,1408.82h-89.13a56.34,56.34,0,0,1,27.4-19.4l17.16-5.58,17.17,5.58A56.43,56.43,0,0,1,1888.6,1408.82Zm-29.06-34.29-15.51,9.21-15.5-9.21a30.33,30.33,0,1,1,31,0Z"
							fill="none"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1899.68,1414.91a4.3,4.3,0,0,1-1.56,1.58,4.22,4.22,0,0,1-2.14.56h-103.9a4.22,4.22,0,0,1-2.14-.56,4.27,4.27,0,0,1-1.55-1.58,4.13,4.13,0,0,1,0-4.29,64.63,64.63,0,0,1,35.93-29,38.56,38.56,0,1,1,39.42,0,64.65,64.65,0,0,1,35.94,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#28a8ea"
						/>
						<path
							d="M1888.6,1408.82h-89.13a56.34,56.34,0,0,1,27.4-19.4l17.16-5.58,17.17,5.58A56.43,56.43,0,0,1,1888.6,1408.82Zm-29.06-34.29-15.51,9.21-15.5-9.21a30.33,30.33,0,1,1,31,0Z"
							fill="none"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1899.68,1414.91a4.3,4.3,0,0,1-1.56,1.58,4.22,4.22,0,0,1-2.14.56h-103.9a4.22,4.22,0,0,1-2.14-.56,4.27,4.27,0,0,1-1.55-1.58,4.13,4.13,0,0,1,0-4.29,64.63,64.63,0,0,1,35.93-29,38.56,38.56,0,1,1,39.42,0,64.65,64.65,0,0,1,35.94,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#28a8ea"
						/>
						<path
							d="M1888.6,1408.82h-89.13a56.34,56.34,0,0,1,27.4-19.4l17.16-5.58,17.17,5.58A56.43,56.43,0,0,1,1888.6,1408.82Zm-29.06-34.29-15.51,9.21-15.5-9.21a30.33,30.33,0,1,1,31,0Z"
							fill="none"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1899.68,1414.91a4.3,4.3,0,0,1-1.56,1.58,4.22,4.22,0,0,1-2.14.56h-103.9a4.22,4.22,0,0,1-2.14-.56,4.27,4.27,0,0,1-1.55-1.58,4.13,4.13,0,0,1,0-4.29,64.63,64.63,0,0,1,35.93-29,38.56,38.56,0,1,1,39.42,0,64.65,64.65,0,0,1,35.94,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#28a8ea"
						/>
						<path
							d="M1888.6,1408.82h-89.13a56.34,56.34,0,0,1,27.4-19.4l17.16-5.58,17.17,5.58A56.43,56.43,0,0,1,1888.6,1408.82Zm-29.06-34.29-15.51,9.21-15.5-9.21a30.33,30.33,0,1,1,31,0Z"
							fill="none"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
				</g>
				<g id="usersBlue">
					<g>
						<path
							d="M1899.68,1414.91a4.3,4.3,0,0,1-1.56,1.58,4.22,4.22,0,0,1-2.14.56h-103.9a4.22,4.22,0,0,1-2.14-.56,4.27,4.27,0,0,1-1.55-1.58,4.13,4.13,0,0,1,0-4.29,64.63,64.63,0,0,1,35.93-29,38.56,38.56,0,1,1,39.42,0,64.65,64.65,0,0,1,35.94,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#28a8ea"
						/>
						<path
							d="M1888.6,1408.82h-89.13a56.34,56.34,0,0,1,27.4-19.4l17.16-5.58,17.17,5.58A56.43,56.43,0,0,1,1888.6,1408.82Zm-29.06-34.29-15.51,9.21-15.5-9.21a30.33,30.33,0,1,1,31,0Z"
							fill="none"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1899.68,1414.91a4.3,4.3,0,0,1-1.56,1.58,4.22,4.22,0,0,1-2.14.56h-103.9a4.22,4.22,0,0,1-2.14-.56,4.27,4.27,0,0,1-1.55-1.58,4.13,4.13,0,0,1,0-4.29,64.63,64.63,0,0,1,35.93-29,38.56,38.56,0,1,1,39.42,0,64.65,64.65,0,0,1,35.94,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#28a8ea"
						/>
						<path
							d="M1888.6,1408.82h-89.13a56.34,56.34,0,0,1,27.4-19.4l17.16-5.58,17.17,5.58A56.43,56.43,0,0,1,1888.6,1408.82Zm-29.06-34.29-15.51,9.21-15.5-9.21a30.33,30.33,0,1,1,31,0Z"
							fill="none"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1899.68,1414.91a4.3,4.3,0,0,1-1.56,1.58,4.22,4.22,0,0,1-2.14.56h-103.9a4.22,4.22,0,0,1-2.14-.56,4.27,4.27,0,0,1-1.55-1.58,4.13,4.13,0,0,1,0-4.29,64.63,64.63,0,0,1,35.93-29,38.56,38.56,0,1,1,39.42,0,64.65,64.65,0,0,1,35.94,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#28a8ea"
						/>
						<path
							d="M1888.6,1408.82h-89.13a56.34,56.34,0,0,1,27.4-19.4l17.16-5.58,17.17,5.58A56.43,56.43,0,0,1,1888.6,1408.82Zm-29.06-34.29-15.51,9.21-15.5-9.21a30.33,30.33,0,1,1,31,0Z"
							fill="none"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1899.68,1414.91a4.3,4.3,0,0,1-1.56,1.58,4.22,4.22,0,0,1-2.14.56h-103.9a4.22,4.22,0,0,1-2.14-.56,4.27,4.27,0,0,1-1.55-1.58,4.13,4.13,0,0,1,0-4.29,64.63,64.63,0,0,1,35.93-29,38.56,38.56,0,1,1,39.42,0,64.65,64.65,0,0,1,35.94,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#28a8ea"
						/>
						<path
							d="M1888.6,1408.82h-89.13a56.34,56.34,0,0,1,27.4-19.4l17.16-5.58,17.17,5.58A56.43,56.43,0,0,1,1888.6,1408.82Zm-29.06-34.29-15.51,9.21-15.5-9.21a30.33,30.33,0,1,1,31,0Z"
							fill="none"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1899.68,1414.91a4.3,4.3,0,0,1-1.56,1.58,4.22,4.22,0,0,1-2.14.56h-103.9a4.22,4.22,0,0,1-2.14-.56,4.27,4.27,0,0,1-1.55-1.58,4.13,4.13,0,0,1,0-4.29,64.63,64.63,0,0,1,35.93-29,38.56,38.56,0,1,1,39.42,0,64.65,64.65,0,0,1,35.94,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#28a8ea"
						/>
						<path
							d="M1888.6,1408.82h-89.13a56.34,56.34,0,0,1,27.4-19.4l17.16-5.58,17.17,5.58A56.43,56.43,0,0,1,1888.6,1408.82Zm-29.06-34.29-15.51,9.21-15.5-9.21a30.33,30.33,0,1,1,31,0Z"
							fill="none"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
					<g>
						<path
							d="M1899.68,1414.91a4.3,4.3,0,0,1-1.56,1.58,4.22,4.22,0,0,1-2.14.56h-103.9a4.22,4.22,0,0,1-2.14-.56,4.27,4.27,0,0,1-1.55-1.58,4.13,4.13,0,0,1,0-4.29,64.63,64.63,0,0,1,35.93-29,38.56,38.56,0,1,1,39.42,0,64.65,64.65,0,0,1,35.94,29,4.13,4.13,0,0,1,0,4.29Z"
							fill="#28a8ea"
						/>
						<path
							d="M1888.6,1408.82h-89.13a56.34,56.34,0,0,1,27.4-19.4l17.16-5.58,17.17,5.58A56.43,56.43,0,0,1,1888.6,1408.82Zm-29.06-34.29-15.51,9.21-15.5-9.21a30.33,30.33,0,1,1,31,0Z"
							fill="none"
							stroke="#28a8ea"
							strokeWidth={6}
						/>
					</g>
				</g>
				<g id="filesFinal">
					<g id="pdf">
						<path
							d="M1872,1306.54h-65.4a1.91,1.91,0,0,0-2,1.8v114.8a1.91,1.91,0,0,0,2,1.8H1898a1.91,1.91,0,0,0,2-1.8v-89.5a4.39,4.39,0,0,0-1.3-3.1l-23.4-22.6A4.71,4.71,0,0,0,1872,1306.54Z"
							fill="#fff"
						/>
						<path
							d="M1899.8,1331.24l-27.3-26.4a5,5,0,0,0-3.2-1.3h-61.5a4.55,4.55,0,0,0-4.6,4.4v115.4a4.55,4.55,0,0,0,4.6,4.4h88.9a4.55,4.55,0,0,0,4.6-4.4v-89.1A5.06,5.06,0,0,0,1899.8,1331.24Zm-29.3-24.1,26.9,26h-25.3a1.47,1.47,0,0,1-1.5-1.5l-.1-24.5Zm26.1,117.8h-88.8a1.47,1.47,0,0,1-1.5-1.5V1308a1.54,1.54,0,0,1,1.5-1.5h59.7v25.2a4.55,4.55,0,0,0,4.6,4.4h26.1v87.3A1.62,1.62,0,0,1,1896.6,1424.94Z"
							fill="#979593"
						/>
						<path
							d="M1887.4,1349.54h-70.5a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.5a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1887.4,1349.54Zm0,8.8h-70.5a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.5a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1887.4,1358.34Zm0,8.9h-70.5a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.5a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1887.4,1367.24Zm0,8.9h-70.5a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.5a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1887.4,1376.14Z"
							fill="#c8c6c4"
						/>
						<path
							d="M1879.8,1388l21.4.1a3,3,0,0,1,3.1,3l-.1,19.2a3,3,0,0,1-3.1,3l-21.4-.1a1.47,1.47,0,0,1-1.5-1.5l.1-22.1A1.5,1.5,0,0,1,1879.8,1388Zm-53.6,23.6a1.54,1.54,0,0,1-1.5,1.5l-21.5-.1a3,3,0,0,1-3-3v-19.2a3,3,0,0,1,3.1-3l21.5.1a1.47,1.47,0,0,1,1.5,1.5l-.1,22.2Z"
							fill="#d65532"
						/>
						<path
							d="M1868.2,1413.94h-32.1a3,3,0,0,1-3.1-3v-20.7a3,3,0,0,1,3.1-3h32.2a3,3,0,0,1,3.1,3v20.7A3.19,3.19,0,0,1,1868.2,1413.94Z"
							fill="none"
							stroke="#d65532"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={3}
						/>
					</g>
					<g id="docx">
						<path
							d="M1820.25,1426.74h88.4a1.47,1.47,0,0,0,1.5-1.5v-89.9h-25.9a4.57,4.57,0,0,1-4.6-4.5v-25.9h-59.5a1.47,1.47,0,0,0-1.5,1.5v118.8a1.63,1.63,0,0,0,1.6,1.5Z"
							fill="#fff"
						/>
						<path
							d="M1909.55,1332.24l-26.8-26.8v25.2a1.54,1.54,0,0,0,1.5,1.5h25.3Z"
							fill="#fff"
						/>
						<path
							d="M1912,1330.24l-27.2-27.1a5.15,5.15,0,0,0-3.2-1.4h-61.3a4.57,4.57,0,0,0-4.6,4.5V1425a4.57,4.57,0,0,0,4.6,4.5h88.4a4.57,4.57,0,0,0,4.6-4.5v-91.6a4.54,4.54,0,0,0-1.3-3.2Zm-29.2-24.7,26.8,26.8h-25.3a1.47,1.47,0,0,1-1.5-1.5Zm26,121.2h-88.5a1.47,1.47,0,0,1-1.5-1.5v-118.8a1.54,1.54,0,0,1,1.5-1.5h59.5v25.9a4.57,4.57,0,0,0,4.6,4.5h25.9v89.9a1.54,1.54,0,0,1-1.5,1.5Z"
							fill="#605e5c"
							fillOpacity={0.64}
						/>
						<path
							d="M1899.55,1387.14h-42.7v3h42.7a1.47,1.47,0,0,0,1.5-1.5A1.42,1.42,0,0,0,1899.55,1387.14Z"
							fill="#103f91"
						/>
						<path
							d="M1899.55,1377.94h-42.7v3h42.7a1.5,1.5,0,0,0,0-3Z"
							fill="#185abd"
						/>
						<path
							d="M1856.85,1371.84h42.7a1.47,1.47,0,0,0,1.5-1.5,1.54,1.54,0,0,0-1.5-1.5h-42.7Z"
							fill="#2b7cd3"
						/>
						<path
							d="M1899.55,1359.64h-42.7v3h42.7a1.5,1.5,0,0,0,0-3Z"
							fill="#41a5ee"
						/>
						<path
							d="M1797.35,1405.44h48.8a6.12,6.12,0,0,0,6.1-6.1v-48.7a6.19,6.19,0,0,0-6.1-6.1h-48.8a6.12,6.12,0,0,0-6.1,6.1v48.7a6.19,6.19,0,0,0,6.1,6.1Z"
							fill="#185abd"
						/>
						<path
							d="M1812.05,1385a18.32,18.32,0,0,1,.2,2.1h.1c.2-1.4.4-2.7.6-4l5.4-23.4h7l5.6,23a32.15,32.15,0,0,1,.7,4.3h.1a24.85,24.85,0,0,1,.6-4.2l4.4-23.2h6.3l-7.8,33.5h-7.4l-5.3-22.2c-.2-.6-.3-1.4-.6-2.5-.2-.8-.2-1.5-.4-2.3h-.1c-.1.5-.2,1.4-.4,2.4-.2.8-.2,1.6-.4,2.4l-5,22.1h-7.5l-7.9-33.5h6.5l4.8,23.4a7.17,7.17,0,0,1,.5,2.1Z"
							fill="#f9f7f7"
						/>
					</g>
					<g id="pptx">
						<path
							d="M1820,1426.69h89a1.58,1.58,0,0,0,1.6-1.6v-89.9h-26.1a4.67,4.67,0,0,1-4.7-4.6v-26H1820a1.58,1.58,0,0,0-1.6,1.6v119a1.56,1.56,0,0,0,1.6,1.5Z"
							fill="#fff"
						/>
						<path
							d="M1909.85,1332.19l-27-26.9v25.3a1.64,1.64,0,0,0,1.6,1.6Z"
							fill="#fff"
						/>
						<path
							d="M1912.25,1330.09l-27.3-27.2a4.82,4.82,0,0,0-3.2-1.3H1820a4.67,4.67,0,0,0-4.7,4.6v118.9a4.61,4.61,0,0,0,4.7,4.6h89.1a4.67,4.67,0,0,0,4.7-4.6v-91.8a7.84,7.84,0,0,0-1.5-3.2Zm-29.4-24.8,27,26.9h-25.6a1.58,1.58,0,0,1-1.6-1.6l.2-25.3Zm26.1,121.4h-89a1.58,1.58,0,0,1-1.6-1.6v-118.9a1.64,1.64,0,0,1,1.6-1.6h59.8v26a4.61,4.61,0,0,0,4.7,4.6h26.1v90.1a1.71,1.71,0,0,1-1.6,1.4Z"
							fill="#605e5c"
							fillOpacity={0.64}
						/>
						<path
							d="M1872.05,1374.89l-1.6-1.6h-12.2l-1.6,1.6v17a23,23,0,0,0,38.3-17.1l-22.9.1Z"
							fill="#ed6c47"
						/>
						<path
							d="M1856.75,1357.79v17h15.3v-22.9A23.84,23.84,0,0,0,1856.75,1357.79Z"
							fill="#ff8f6b"
						/>
						<path
							d="M1876.75,1347.39v22.9h23A23,23,0,0,0,1876.75,1347.39Z"
							fill="#ffc7b5"
						/>
						<path
							d="M1796.85,1405.29h49.2a6.1,6.1,0,0,0,6.1-6v-48.8a6,6,0,0,0-6.1-6h-49.2a6.1,6.1,0,0,0-6.1,6v48.8a6.1,6.1,0,0,0,6.1,6Z"
							fill="#c43e1c"
						/>
						<path
							d="M1824.25,1356.49c4.6,0,8.1,1,10.4,3s3.6,4.9,3.6,8.8a13,13,0,0,1-1.7,6.6,11.85,11.85,0,0,1-5.1,4.5,16.53,16.53,0,0,1-7.7,1.6h-7v13.8h-7.7v-38.2h15.2Zm-7.3,18.4h6.1c2.4,0,4.2-.5,5.4-1.6a6,6,0,0,0,1.8-4.7c0-3.9-2.3-5.9-7-5.9H1817v12.2Z"
							fill="#f9f7f7"
						/>
					</g>
					<g id="xlsx">
						<path
							d="M1820.53,1426.49h87.8a1.47,1.47,0,0,0,1.5-1.5v-89.6H1884a4.48,4.48,0,0,1-4.5-4.5v-25.8h-59a1.47,1.47,0,0,0-1.5,1.5v118.5a1.61,1.61,0,0,0,1.5,1.4Z"
							fill="#fff"
						/>
						<path
							d="M1909.33,1332.19l-26.6-26.7v25.2a1.54,1.54,0,0,0,1.5,1.5Z"
							fill="#fff"
						/>
						<path
							d="M1911.63,1330.29l-27-27.1a4.38,4.38,0,0,0-3.2-1.3h-61a4.48,4.48,0,0,0-4.5,4.5v118.5a4.55,4.55,0,0,0,4.5,4.5h87.8a4.48,4.48,0,0,0,4.5-4.5v-91.4a3.85,3.85,0,0,0-1.1-3.2Zm-29-24.7,26.6,26.7h-25.1a1.47,1.47,0,0,1-1.5-1.5v-25.2Zm25.8,120.9h-87.8a1.47,1.47,0,0,1-1.5-1.5v-118.5a1.54,1.54,0,0,1,1.5-1.5h59v25.8a4.55,4.55,0,0,0,4.5,4.5h25.8v89.6a1.63,1.63,0,0,1-1.5,1.6Z"
							fill="#605e5c"
							fillOpacity={0.64}
						/>
						<path
							d="M1899.33,1390h-16.7a1.47,1.47,0,0,1-1.5-1.5v-3a1.54,1.54,0,0,1,1.5-1.5h16.7a1.47,1.47,0,0,1,1.5,1.5v3A1.47,1.47,0,0,1,1899.33,1390Z"
							fill="#134a2c"
						/>
						<path
							d="M1875,1390h-16.7a1.47,1.47,0,0,1-1.5-1.5v-3a1.54,1.54,0,0,1,1.5-1.5H1875a1.47,1.47,0,0,1,1.5,1.5v3A1.42,1.42,0,0,1,1875,1390Z"
							fill="#185c37"
						/>
						<path
							d="M1899.33,1377.89h-16.7a1.47,1.47,0,0,1-1.5-1.5v-3a1.54,1.54,0,0,1,1.5-1.5h16.7a1.47,1.47,0,0,1,1.5,1.5v3A1.61,1.61,0,0,1,1899.33,1377.89Z"
							fill="#21a366"
						/>
						<path
							d="M1875,1377.89h-16.7a1.47,1.47,0,0,1-1.5-1.5v-3a1.54,1.54,0,0,1,1.5-1.5H1875a1.47,1.47,0,0,1,1.5,1.5v3A1.54,1.54,0,0,1,1875,1377.89Z"
							fill="#107c41"
						/>
						<path
							d="M1899.33,1365.69h-16.7a1.47,1.47,0,0,1-1.5-1.5v-3a1.54,1.54,0,0,1,1.5-1.5h16.7a1.47,1.47,0,0,1,1.5,1.5v3A1.54,1.54,0,0,1,1899.33,1365.69Z"
							fill="#33c481"
						/>
						<path
							d="M1875,1365.69h-16.7a1.47,1.47,0,0,1-1.5-1.5v-3a1.54,1.54,0,0,1,1.5-1.5H1875a1.47,1.47,0,0,1,1.5,1.5v3A1.47,1.47,0,0,1,1875,1365.69Z"
							fill="#21a366"
						/>
						<path
							d="M1797.83,1405.19h48.4a6.19,6.19,0,0,0,6.1-6.1v-48.6a6.19,6.19,0,0,0-6.1-6.1h-48.4a6.19,6.19,0,0,0-6.1,6.1v48.6a6.12,6.12,0,0,0,6.1,6.1Z"
							fill="#107c41"
						/>
						<path
							d="M1805.33,1393l11.9-18.2-10.9-18.1h8.8l6,11.6c.6,1.1.9,2,1.1,2.4h.1c.4-.9.8-1.7,1.3-2.6l6.4-11.5h8.1l-11.2,18.1,11.5,18.4h-8.6l-6.9-12.8a13,13,0,0,1-.8-1.7h-.1c-.2.4-.4,1-.8,1.7l-7.1,12.8-8.8-.1Z"
							fill="#f9f7f7"
						/>
					</g>
					<g id="picture">
						<path
							d="M1910.25,1426.89h-116a3.14,3.14,0,0,1-3.1-3.2v-116.1a3.2,3.2,0,0,1,3.1-3.2h116a3.14,3.14,0,0,1,3.1,3.2v116.2A3.05,3.05,0,0,1,1910.25,1426.89Z"
							fill="#fff"
							stroke="#979593"
							strokeMiterlimit={10}
							strokeWidth={5}
						/>
						<path
							d="M1867.55,1366l-8.3,11.2a1.6,1.6,0,0,1-2.5,0l-13.9-17.8a3.1,3.1,0,0,0-4.9,0l-24.3,31.1a3.18,3.18,0,0,0,2.4,5.1H1888a3.1,3.1,0,0,0,2.5-5l-17.9-24.6A3,3,0,0,0,1867.55,1366Z"
							fill="none"
							stroke="#a6ccc3"
							strokeMiterlimit={10}
							strokeWidth={2}
						/>
						<circle
							cx={1881.96}
							cy={1345.4}
							r={9.4}
							transform="translate(474.67 3181.54) rotate(-88.09)"
							fill="none"
							stroke="#ff9810"
							strokeMiterlimit={10}
							strokeWidth={2}
						/>
					</g>
					<g>
						<path
							d="M1899.34,1416.94H1782.6a3.16,3.16,0,0,1-3.15-3.18v-118a3.17,3.17,0,0,1,3.15-3.19h116.74a3.17,3.17,0,0,1,3.16,3.19v118A3.16,3.16,0,0,1,1899.34,1416.94Z"
							fill="#fff"
							stroke="#979593"
							strokeMiterlimit={10}
							strokeWidth={2}
						/>
						<path
							d="M1833.09,1370.64v-30.85l30-7.4v30.85m-29.45-13.59,29.45-7.43"
							fill="none"
							stroke="#69afe5"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
						/>
						<ellipse
							cx={1825.99}
							cy={1370}
							rx={7.1}
							ry={7.17}
							fill="none"
							stroke="#69afe5"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
						/>
						<ellipse
							cx={1855.96}
							cy={1363.63}
							rx={7.1}
							ry={7.17}
							fill="none"
							stroke="#69afe5"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
						/>
					</g>
					<g>
						<path
							d="M1896.35,1413.94H1785.64a3.05,3.05,0,0,1-3-3.11v-115.1a3.05,3.05,0,0,1,3-3.11h110.71a3,3,0,0,1,3,3.11v115.1A3,3,0,0,1,1896.35,1413.94Z"
							fill="#fff"
							stroke="#979593"
							strokeMiterlimit={10}
							strokeWidth={2}
						/>
						<path
							d="M1863.44,1353.28l-35.91-21v42Z"
							fill="none"
							stroke="#69afe5"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
						/>
					</g>
				</g>
				<g id="files">
					<g id="pdf-2" data-name="pdf">
						<path
							d="M1814.17,265.18h-65.4a1.91,1.91,0,0,0-2,1.8v114.8a1.91,1.91,0,0,0,2,1.8h91.4a1.91,1.91,0,0,0,2-1.8v-89.5a4.39,4.39,0,0,0-1.3-3.1l-23.4-22.6A4.71,4.71,0,0,0,1814.17,265.18Z"
							fill="#fff"
						/>
						<path
							d="M1842,289.88l-27.3-26.4a5,5,0,0,0-3.2-1.3H1750a4.55,4.55,0,0,0-4.6,4.4V382a4.55,4.55,0,0,0,4.6,4.4h88.9a4.55,4.55,0,0,0,4.6-4.4v-89.1A5.06,5.06,0,0,0,1842,289.88Zm-29.3-24.1,26.9,26h-25.3a1.47,1.47,0,0,1-1.5-1.5l-.1-24.5Zm26.1,117.8H1750a1.47,1.47,0,0,1-1.5-1.5V266.68a1.54,1.54,0,0,1,1.5-1.5h59.7v25.2a4.55,4.55,0,0,0,4.6,4.4h26.1v87.3A1.62,1.62,0,0,1,1838.77,383.58Z"
							fill="#979593"
						/>
						<path
							d="M1829.57,308.18h-70.5a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.5a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1829.57,308.18Zm0,8.8h-70.5a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.5a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1829.57,317Zm0,8.9h-70.5a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.5a1.47,1.47,0,0,1,1.5,1.5h0A1.42,1.42,0,0,1,1829.57,325.88Zm0,8.9h-70.5a1.47,1.47,0,0,1-1.5-1.5h0a1.54,1.54,0,0,1,1.5-1.5h70.5a1.47,1.47,0,0,1,1.5,1.5h0A1.47,1.47,0,0,1,1829.57,334.78Z"
							fill="#c8c6c4"
						/>
						<path
							d="M1822,346.68l21.4.1a3,3,0,0,1,3.1,3l-.1,19.2a3,3,0,0,1-3.1,3l-21.4-.1a1.47,1.47,0,0,1-1.5-1.5l.1-22.1A1.5,1.5,0,0,1,1822,346.68Zm-53.6,23.6a1.54,1.54,0,0,1-1.5,1.5l-21.5-.1a3,3,0,0,1-3-3v-19.2a3,3,0,0,1,3.1-3l21.5.1a1.47,1.47,0,0,1,1.5,1.5l-.1,22.2Z"
							fill="#d65532"
						/>
						<path
							d="M1810.37,372.58h-32.1a3,3,0,0,1-3.1-3v-20.7a3,3,0,0,1,3.1-3h32.2a3,3,0,0,1,3.1,3v20.7A3.19,3.19,0,0,1,1810.37,372.58Z"
							fill="none"
							stroke="#d65532"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={3}
						/>
					</g>
					<g id="docx-2" data-name="docx">
						<path
							d="M1762.42,385.38h88.4a1.47,1.47,0,0,0,1.5-1.5V294h-25.9a4.57,4.57,0,0,1-4.6-4.5v-25.9h-59.5a1.47,1.47,0,0,0-1.5,1.5v118.8a1.63,1.63,0,0,0,1.6,1.5Z"
							fill="#fff"
						/>
						<path
							d="M1851.72,290.88l-26.8-26.8v25.2a1.54,1.54,0,0,0,1.5,1.5h25.3Z"
							fill="#fff"
						/>
						<path
							d="M1854.12,288.88l-27.2-27.1a5.15,5.15,0,0,0-3.2-1.4h-61.3a4.57,4.57,0,0,0-4.6,4.5v118.8a4.57,4.57,0,0,0,4.6,4.5h88.4a4.57,4.57,0,0,0,4.6-4.5v-91.6a4.54,4.54,0,0,0-1.3-3.2Zm-29.2-24.7,26.8,26.8h-25.3a1.47,1.47,0,0,1-1.5-1.5Zm26,121.2h-88.5a1.47,1.47,0,0,1-1.5-1.5V265.08a1.54,1.54,0,0,1,1.5-1.5h59.5v25.9a4.57,4.57,0,0,0,4.6,4.5h25.9v89.9a1.54,1.54,0,0,1-1.5,1.5Z"
							fill="#605e5c"
							fillOpacity={0.64}
						/>
						<path
							d="M1841.72,345.78H1799v3h42.7a1.47,1.47,0,0,0,1.5-1.5A1.42,1.42,0,0,0,1841.72,345.78Z"
							fill="#103f91"
						/>
						<path
							d="M1841.72,336.58H1799v3h42.7a1.5,1.5,0,1,0,0-3Z"
							fill="#185abd"
						/>
						<path
							d="M1799,330.48h42.7a1.47,1.47,0,0,0,1.5-1.5,1.54,1.54,0,0,0-1.5-1.5H1799Z"
							fill="#2b7cd3"
						/>
						<path
							d="M1841.72,318.28H1799v3h42.7a1.5,1.5,0,0,0,0-3Z"
							fill="#41a5ee"
						/>
						<path
							d="M1739.52,364.08h48.8a6.12,6.12,0,0,0,6.1-6.1v-48.7a6.19,6.19,0,0,0-6.1-6.1h-48.8a6.12,6.12,0,0,0-6.1,6.1V358a6.19,6.19,0,0,0,6.1,6.1Z"
							fill="#185abd"
						/>
						<path
							d="M1754.22,343.68a18.32,18.32,0,0,1,.2,2.1h.1c.2-1.4.4-2.7.6-4l5.4-23.4h7l5.6,23a32.15,32.15,0,0,1,.7,4.3h.1a24.85,24.85,0,0,1,.6-4.2l4.4-23.2h6.3l-7.8,33.5H1770l-5.3-22.2c-.2-.6-.3-1.4-.6-2.5-.2-.8-.2-1.5-.4-2.3h-.1c-.1.5-.2,1.4-.4,2.4-.2.8-.2,1.6-.4,2.4l-5,22.1h-7.5l-7.9-33.5h6.5l4.8,23.4a7.17,7.17,0,0,1,.5,2.1Z"
							fill="#f9f7f7"
						/>
					</g>
					<g id="pptx-2" data-name="pptx">
						<path
							d="M1762.12,385.33h89a1.58,1.58,0,0,0,1.6-1.6v-89.9h-26.1a4.67,4.67,0,0,1-4.7-4.6v-26h-59.8a1.58,1.58,0,0,0-1.6,1.6v119a1.56,1.56,0,0,0,1.6,1.5Z"
							fill="#fff"
						/>
						<path
							d="M1852,290.83l-27-26.9v25.3a1.64,1.64,0,0,0,1.6,1.6Z"
							fill="#fff"
						/>
						<path
							d="M1854.42,288.73l-27.3-27.2a4.82,4.82,0,0,0-3.2-1.3h-61.8a4.67,4.67,0,0,0-4.7,4.6v118.9a4.61,4.61,0,0,0,4.7,4.6h89.1a4.67,4.67,0,0,0,4.7-4.6v-91.8a7.84,7.84,0,0,0-1.5-3.2Zm-29.4-24.8,27,26.9h-25.6a1.58,1.58,0,0,1-1.6-1.6l.2-25.3Zm26.1,121.4h-89a1.58,1.58,0,0,1-1.6-1.6V264.83a1.64,1.64,0,0,1,1.6-1.6h59.8v26a4.61,4.61,0,0,0,4.7,4.6h26.1v90.1a1.71,1.71,0,0,1-1.6,1.4Z"
							fill="#605e5c"
							fillOpacity={0.64}
						/>
						<path
							d="M1814.22,333.53l-1.6-1.6h-12.2l-1.6,1.6v17a23,23,0,0,0,38.3-17.1l-22.9.1Z"
							fill="#ed6c47"
						/>
						<path
							d="M1798.92,316.43v17h15.3v-22.9A23.84,23.84,0,0,0,1798.92,316.43Z"
							fill="#ff8f6b"
						/>
						<path
							d="M1818.92,306v22.9h23A23,23,0,0,0,1818.92,306Z"
							fill="#ffc7b5"
						/>
						<path
							d="M1739,363.93h49.2a6.1,6.1,0,0,0,6.1-6v-48.8a6,6,0,0,0-6.1-6H1739a6.1,6.1,0,0,0-6.1,6v48.8a6.1,6.1,0,0,0,6.1,6Z"
							fill="#c43e1c"
						/>
						<path
							d="M1766.42,315.13c4.6,0,8.1,1,10.4,3s3.6,4.9,3.6,8.8a13,13,0,0,1-1.7,6.6,11.85,11.85,0,0,1-5.1,4.5,16.53,16.53,0,0,1-7.7,1.6h-7v13.8h-7.7v-38.2h15.2Zm-7.3,18.4h6.1c2.4,0,4.2-.5,5.4-1.6a6,6,0,0,0,1.8-4.7c0-3.9-2.3-5.9-7-5.9h-6.3v12.2Z"
							fill="#f9f7f7"
						/>
					</g>
					<g id="xlsx-2" data-name="xlsx">
						<path
							d="M1762.7,385.13h87.8a1.47,1.47,0,0,0,1.5-1.5V294h-25.8a4.48,4.48,0,0,1-4.5-4.5v-25.8h-59a1.47,1.47,0,0,0-1.5,1.5v118.5a1.61,1.61,0,0,0,1.5,1.4Z"
							fill="#fff"
						/>
						<path
							d="M1851.5,290.83l-26.6-26.7v25.2a1.54,1.54,0,0,0,1.5,1.5Z"
							fill="#fff"
						/>
						<path
							d="M1853.8,288.93l-27-27.1a4.38,4.38,0,0,0-3.2-1.3h-61a4.48,4.48,0,0,0-4.5,4.5v118.5a4.55,4.55,0,0,0,4.5,4.5h87.8a4.48,4.48,0,0,0,4.5-4.5v-91.4a3.85,3.85,0,0,0-1.1-3.2Zm-29-24.7,26.6,26.7h-25.1a1.47,1.47,0,0,1-1.5-1.5v-25.2Zm25.8,120.9h-87.8a1.47,1.47,0,0,1-1.5-1.5V265.13a1.54,1.54,0,0,1,1.5-1.5h59v25.8a4.55,4.55,0,0,0,4.5,4.5h25.8v89.6a1.63,1.63,0,0,1-1.5,1.6Z"
							fill="#605e5c"
							fillOpacity={0.64}
						/>
						<path
							d="M1841.5,348.63h-16.7a1.47,1.47,0,0,1-1.5-1.5v-3a1.54,1.54,0,0,1,1.5-1.5h16.7a1.47,1.47,0,0,1,1.5,1.5v3A1.47,1.47,0,0,1,1841.5,348.63Z"
							fill="#134a2c"
						/>
						<path
							d="M1817.2,348.63h-16.7a1.47,1.47,0,0,1-1.5-1.5v-3a1.54,1.54,0,0,1,1.5-1.5h16.7a1.47,1.47,0,0,1,1.5,1.5v3A1.42,1.42,0,0,1,1817.2,348.63Z"
							fill="#185c37"
						/>
						<path
							d="M1841.5,336.53h-16.7a1.47,1.47,0,0,1-1.5-1.5v-3a1.54,1.54,0,0,1,1.5-1.5h16.7a1.47,1.47,0,0,1,1.5,1.5v3A1.61,1.61,0,0,1,1841.5,336.53Z"
							fill="#21a366"
						/>
						<path
							d="M1817.2,336.53h-16.7a1.47,1.47,0,0,1-1.5-1.5v-3a1.54,1.54,0,0,1,1.5-1.5h16.7a1.47,1.47,0,0,1,1.5,1.5v3A1.54,1.54,0,0,1,1817.2,336.53Z"
							fill="#107c41"
						/>
						<path
							d="M1841.5,324.33h-16.7a1.47,1.47,0,0,1-1.5-1.5v-3a1.54,1.54,0,0,1,1.5-1.5h16.7a1.47,1.47,0,0,1,1.5,1.5v3A1.54,1.54,0,0,1,1841.5,324.33Z"
							fill="#33c481"
						/>
						<path
							d="M1817.2,324.33h-16.7a1.47,1.47,0,0,1-1.5-1.5v-3a1.54,1.54,0,0,1,1.5-1.5h16.7a1.47,1.47,0,0,1,1.5,1.5v3A1.47,1.47,0,0,1,1817.2,324.33Z"
							fill="#21a366"
						/>
						<path
							d="M1740,363.83h48.4a6.19,6.19,0,0,0,6.1-6.1v-48.6a6.19,6.19,0,0,0-6.1-6.1H1740a6.19,6.19,0,0,0-6.1,6.1v48.6a6.12,6.12,0,0,0,6.1,6.1Z"
							fill="#107c41"
						/>
						<path
							d="M1747.5,351.63l11.9-18.2-10.9-18.1h8.8l6,11.6c.6,1.1.9,2,1.1,2.4h.1c.4-.9.8-1.7,1.3-2.6l6.4-11.5h8.1l-11.2,18.1,11.5,18.4H1772l-6.9-12.8a13,13,0,0,1-.8-1.7h-.1c-.2.4-.4,1-.8,1.7l-7.1,12.8-8.8-.1Z"
							fill="#f9f7f7"
						/>
					</g>
					<g id="picture-2" data-name="picture">
						<path
							d="M1852.42,385.53h-116a3.14,3.14,0,0,1-3.1-3.2V266.23a3.2,3.2,0,0,1,3.1-3.2h116a3.14,3.14,0,0,1,3.1,3.2v116.2A3.05,3.05,0,0,1,1852.42,385.53Z"
							fill="#fff"
							stroke="#979593"
							strokeMiterlimit={10}
							strokeWidth={5}
						/>
						<path
							d="M1809.72,324.63l-8.3,11.2a1.6,1.6,0,0,1-2.5,0L1785,318a3.1,3.1,0,0,0-4.9,0l-24.3,31.1a3.18,3.18,0,0,0,2.4,5.1h71.9a3.1,3.1,0,0,0,2.5-5l-17.9-24.6A3,3,0,0,0,1809.72,324.63Z"
							fill="none"
							stroke="#a6ccc3"
							strokeMiterlimit={10}
							strokeWidth={2}
						/>
						<circle
							cx={1824.13}
							cy={304.03}
							r={9.4}
							transform="translate(1459.56 2117.04) rotate(-88.09)"
							fill="none"
							stroke="#ff9810"
							strokeMiterlimit={10}
							strokeWidth={2}
						/>
					</g>
					<g>
						<path
							d="M1852.26,390.4H1735.52a3.17,3.17,0,0,1-3.15-3.19V269.26a3.16,3.16,0,0,1,3.15-3.18h116.74a3.16,3.16,0,0,1,3.16,3.18v118A3.17,3.17,0,0,1,1852.26,390.4Z"
							fill="#fff"
							stroke="#979593"
							strokeMiterlimit={10}
							strokeWidth={2}
						/>
						<path
							d="M1786,344.1V313.25l30-7.41v30.85m-29.45-13.58,29.45-7.44"
							fill="none"
							stroke="#69afe5"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
						/>
						<ellipse
							cx={1778.91}
							cy={343.46}
							rx={7.1}
							ry={7.17}
							fill="none"
							stroke="#69afe5"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
						/>
						<ellipse
							cx={1808.88}
							cy={337.08}
							rx={7.1}
							ry={7.17}
							fill="none"
							stroke="#69afe5"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
						/>
					</g>
					<g>
						<path
							d="M1849.27,387.4H1738.56a3.05,3.05,0,0,1-3-3.11V269.19a3.05,3.05,0,0,1,3-3.11h110.71a3.05,3.05,0,0,1,3,3.11v115.1A3.05,3.05,0,0,1,1849.27,387.4Z"
							fill="#fff"
							stroke="#979593"
							strokeMiterlimit={10}
							strokeWidth={2}
						/>
						<path
							d="M1816.36,326.74l-35.91-21v42Z"
							fill="none"
							stroke="#69afe5"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
						/>
					</g>
				</g>
				<g id="STYLE_COLOR" data-name="STYLE COLOR">
					<path
						d="M1825.4,344.68l69.31-57.4c-14.4-48.6-71.5-77.9-127.6-65.5-25.3,5.6-47.2,19.2-61.6,38.1C1706.6,259.78,1825.4,344.68,1825.4,344.68Z"
						fill="#0364b8"
					/>
					<path
						d="M1750.7,270.68h0a92.25,92.25,0,0,0-44.1-10.8h-1.1c-46.3.5-83.4,33.4-82.8,73.6.2,14.6,5.5,28.7,15.1,40.7l103.8-11.3,79.6-55.6Z"
						fill="#0078d4"
					/>
					<path
						d="M1894.7,287.18c-1.5-.1-3.1-.1-4.6-.1a76.79,76.79,0,0,0-27,4.8h0l-42,15.3,46.3,49.6,82.7,17.4c17.9-28.7,5.5-64.6-27.7-80a76.45,76.45,0,0,0-27.7-7Z"
						fill="#1490df"
					/>
					<path
						d="M1637.81,374.08c15.69,19.5,41.4,31.2,68.8,31.2h183.5c25.1,0,48.1-11.9,60-31l-129-67Z"
						fill="#28a8ea"
					/>
				</g>
				<g>
					<path
						d="M898.49,1246.3H791.39l-26.8-29.8h-67c-14.7,0-26.8,13.4-26.8,29.8v178.6c0,16.4,12.1,29.8,26.8,29.8h207.6c11.4,0,20.1-9.7,20.1-22.3V1276.2C925.29,1259.7,913.19,1246.3,898.49,1246.3Z"
						fill="#ffa000"
					/>
					<path
						d="M926.59,1290.9H746.49c-12.7,0-24.1,10.4-26.1,24.6l-22.8,139.1h212.3c12.7,0,24.1-10.4,26.1-24.6l16.7-104.2C956.09,1308,943.39,1290.9,926.59,1290.9Z"
						fill="#ffca28"
					/>
				</g>
				<g id="networkfolder">
					<path
						d="M1190.87,512h-102l-25.5-27h-63.8c-14.1,0-25.5,12.2-25.5,27v162.1c0,14.9,11.4,27,25.5,27h197.7c10.8,0,19.1-8.8,19.1-20.3V539C1216.37,524.09,1204.87,512,1190.87,512Z"
						fill="#286ee6"
					/>
					<path
						d="M1217.67,552.49h-171.5c-12.2,0-23,9.5-24.9,22.3l-21.7,126.3h202.1c12.2,0,23-9.5,24.9-22.3l15.9-94.5C1245.67,568,1233.57,552.49,1217.67,552.49Z"
						fill="#4286fb"
					/>
					<path
						d="M1142.37,602.79c9.4,0,17-7.3,17-16.2s-7.7-16.2-17-16.2-17,7.3-17,16.2S1133,602.79,1142.37,602.79Z"
						fill="none"
						stroke="#fff"
						strokeWidth={2}
					/>
					<path
						d="M1142.37,678.39c9.4,0,17-7.3,17-16.2s-7.7-16.2-17-16.2-17,7.3-17,16.2S1133,678.39,1142.37,678.39Z"
						fill="none"
						stroke="#fff"
						strokeWidth={2}
					/>
					<path
						d="M1074.37,640.59c9.4,0,17-7.3,17-16.2s-7.7-16.2-17-16.2-17,7.3-17,16.2S1065,640.59,1074.37,640.59Z"
						fill="none"
						stroke="#fff"
						strokeWidth={2}
					/>
					<path
						d="M1127.67,594.69l-38.6,21.5m38.6,37.8-38.6-21.5"
						fill="none"
						stroke="#fff"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
					/>
				</g>
				<path
					d="M1955.14,2447.86c4.19,5.87,3.35,13.41-1.68,17.6-5.87,4.19-14.25,3.35-18.44-1.68l-29.33-37.71-28.5,36.88c-4.19,5.86-12.57,5.86-18.44,1.67s-6.71-11.73-2.52-17.6l33.53-43.58-33.53-43.59c-4.19-5.87-2.51-14.25,2.52-18.44,5.87-4.19,14.25-2.51,18.44,2.51l28.5,37.72,29.33-36c4.19-5.87,11.74-6.7,18.44-2.51,5.87,4.19,5.87,12.57,1.68,18.44l-32.69,42.74Zm-152.55-5c-21.79,0-39.39-16.76-39.39-38.56,0-20.95,17.6-38.55,39.39-38.55s39.39,17.6,39.39,38.55C1841.15,2426.07,1823.55,2442.83,1802.59,2442.83Zm-115.67,0c-21.79,0-39.39-16.76-39.39-38.56,0-20.95,17.6-38.55,39.39-38.55s39.4,17.6,39.4,38.55C1726.32,2426.07,1708.72,2442.83,1686.92,2442.83Zm115.67-102.26a64.87,64.87,0,0,0-57,33.53c-10.9-20.12-32.69-33.53-57.84-33.53a68.88,68.88,0,0,0-39.39,12.57V2299.5a12.89,12.89,0,0,0-12.57-12.57c-7.55,0-13.42,5.87-13.42,12.57v105.61a64.23,64.23,0,0,0,64.54,62.86,66,66,0,0,0,57.84-34.36c10.89,20.12,32.69,34.36,57,34.36,36,0,65.38-28.49,65.38-64.53C1868,2369.07,1838.63,2340.57,1802.59,2340.57Z"
					fill="#0071f7"
				/>
				<g>
					<path
						d="M975.62,2119.33l11.69,20.19a27.58,27.58,0,0,0,10,10l41.76-72.28H955.58a27.5,27.5,0,0,0,3.64,13.67Z"
						fill="#0066da"
					/>
					<path
						d="M1088.14,1992.23,1046.38,1920a27.58,27.58,0,0,0-10,10L959.22,2063.6a27.42,27.42,0,0,0-3.64,13.66h83.51Z"
						fill="#00ac47"
					/>
					<path
						d="M1178.94,2149.54a27.61,27.61,0,0,0,10-10l4.85-8.35,23.24-40.24a27.5,27.5,0,0,0,3.64-13.67h-83.52l17.77,34.93Z"
						fill="#ea4335"
					/>
					<path
						d="M1088.14,1992.23,1129.9,1920a26.7,26.7,0,0,0-13.67-3.64h-56.18a28.26,28.26,0,0,0-13.67,3.64Z"
						fill="#00832d"
					/>
					<path
						d="M1137.19,2077.26h-98.1l-41.76,72.28a26.71,26.71,0,0,0,13.67,3.65h154.28a28.23,28.23,0,0,0,13.66-3.65Z"
						fill="#2684fc"
					/>
					<path
						d="M1178.49,1996.78,1139.92,1930a27.58,27.58,0,0,0-10-10l-41.76,72.28,49,85h83.36a27.6,27.6,0,0,0-3.64-13.66Z"
						fill="#ffba00"
					/>
				</g>
				<g>
					<path
						d="M1776,1361l69.16,87.49-132.36.67a55.12,55.12,0,0,1,0-110.23h0l1.3-1c5.78-25,30.19-43.74,59.45-43.74a64.93,64.93,0,0,1,33.6,9.2L1776,1361Z"
						fill="#ed3030"
					/>
					<path
						d="M2017.91,1342.19a54.71,54.71,0,0,0-18.62-3.24h0c-.49-.38-.79-.66-1.3-1-3.39-14.72-13.27-27.26-26.78-35.09a65,65,0,0,0-32.66-8.64c-.41,0-.81,0-1.22,0a65.41,65.41,0,0,0-82.57-42.8l-58.25,107.73,70.56,89.22-.12.15,132.36.68a54.84,54.84,0,0,0,12.31-1.39,49.93,49.93,0,0,0,8.49-2.69,55.12,55.12,0,0,0-2.18-102.92"
						fill="#4db5d6"
					/>
				</g>
			</svg>
		</div>
	);
}

export default Icons;