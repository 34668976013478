import './benefits.css';
import mirrorDataIcon from '../../assets/mirrordata.svg';
import backupDataIcon from '../../assets/backupdata.svg';
import orchestrateDataIcon from '../../assets/orchestratedata.svg';
import { Grid } from 'semantic-ui-react';
import React from 'react';



function Benefits() {
    return (
        <div className="benefitsComponent">
            <div className="benefitsContainer"> 
                <Grid container> 
                    <Grid.Row>
                        <Grid.Column>
                            <div className='benefitsHeading'>Benefits:</div>
                            <div className='benefitsText'>Customers are using Bifrust platform to make their data discovery, data transmit and data transform journey a success.</div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='benefitItemsSection'>
                        <Grid centered container>
                                <Grid.Column mobile={8} tablet={8} computer={5}>
                                    <div className='benefitsItem'>
                                        <div className='benefitsItemIcon'><img src={mirrorDataIcon} alt="logo" /></div>
                                        <div className='benefitsItemText'><h3>Mirror Folders</h3> Schedule tasks to mirror folders across local storage devices and cloud storage platforms.</div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={5}>
                                <div className='benefitsItem'>
                                        <div><img src={backupDataIcon} alt="logo" /></div>
                                        <div className='benefitsItemText'><h3>Backup</h3> Create backups tasks with custom schedules to easily back up cloud and local storage devices.</div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={5}>
                                <div className='benefitsItem'>
                                        <div><img src={orchestrateDataIcon} alt="logo" /></div>
                                        <div className='benefitsItemText'><h3>Orchestration</h3> Easily move data based on rules and filters between storage platforms and devices. </div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={5}>
                                    <div className='benefitsItem'>
                                        <div><img src={mirrorDataIcon} alt="logo" /></div>
                                        <div className='benefitsItemText'><h3>Load Balance</h3> Load balance between storage platforms to reduce load on any one platform and optimize storage costs.</div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={5}>
                                <div className='benefitsItem'>
                                        <div><img src={mirrorDataIcon} alt="logo" /></div>
                                        <div className='benefitsItemText'><h3>Report</h3>  Planning data transit and transform activties easy with file and folder level based reporting.</div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={5}>
                                <div className='benefitsItem'>
                                        <div><img src={mirrorDataIcon} alt="logo" /></div>
                                        <div className='benefitsItemText'><h3>Migrate</h3> Migrating to another platform or storage device is easier than ever.</div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={5}>
                                    <div className='benefitsItem'>
                                        <div><img src={mirrorDataIcon} alt="logo" /></div>
                                        <div className='benefitsItemText'><h3>Seed</h3> Seed data or create coexistence between cloud platforms and local devices before cutover.</div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={5}>
                                <div className='benefitsItem'>
                                        <div><img src={mirrorDataIcon} alt="logo" /></div>
                                        <div className='benefitsItemText'><h3>Download</h3> Download specific files or folders from cloud storage accounts or local storage devices.</div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={5}>
                                <div className='benefitsItem'>
                                        <div><img src={mirrorDataIcon} alt="logo" /></div>
                                        <div className='benefitsItemText'><h3>Snapshot</h3> Create snapshots of cloud users, folders or files to local devices or other cloud storages.</div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column mobile={8} tablet={8} computer={5}>
                                <div className='benefitsItem'>
                                        {/* <div><img src={mirrorDataIcon} alt="logo" /></div>
                                        <div className='benefitsItemText'><h3>Snapshot</h3> Create snapshots of cloud users, folders or files to local devices or other cloud storages.</div> */}
                                    </div>
                                </Grid.Column>
                     
                        </Grid> 
                    </Grid.Row>
                </Grid>                
            </div>    
        </div>
    );
}

export default Benefits;
