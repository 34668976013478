import './connectivity.css';
import box from '../../assets/box.svg';
import o365 from '../../assets/o365.svg';
import onedrive from '../../assets/onedrive.svg';
import teams from '../../assets/teams.svg';
import folder from '../../assets/folder.svg';
import sharepoint from '../../assets/sharepoint.svg';
import gdrive from '../../assets/gdrive.svg';
import networkFolder from '../../assets/networkFolderIcon.svg';
import { Grid } from 'semantic-ui-react';
import React from 'react';

function Connectivity() {
    return (
        <div className="connectivityComponent"> 
            <div className="connectivityContainer">
                <Grid container relaxed centered columns={2}>
                    <Grid.Row streched>
                        <Grid.Column  verticalAlign='middle' textAlign='left' mobile={16} tablet={7} computer={7}>
                            <div className='connectivityHeading showBorder'>Connectivity</div>
                            <div className='connectivitysText showBorder'>Manage storage cloud platforms and local storage devices with ease.</div>
                        </Grid.Column>
                        <Grid.Column   mobile={16} tablet={7} computer={7}>
                            <Grid.Row>
                                <div className='conntectorIconSection'>
                                    <div><img src={box} className="connectorIcon" alt="widget" /></div>
                                    <div><img src={o365} className="connectorIcon office365" alt="widget" /></div>
                                    <div><img src={onedrive} className="connectorIcon onedrive" alt="widget" /></div>
                                    {/* <div><img src={teams} className="connectorIcon teams" alt="widget" /></div> */}
                                </div>
                            </Grid.Row>
                            <Grid.Row>
                                <div className='conntectorIconSection'>
                                    <div><img src={folder} className="connectorIcon" alt="widget" /></div>
                                    {/* <div><img src={sharepoint} className="connectorIcon sharepoint" alt="widget" /></div> */}
                                    <div><img src={gdrive} className="connectorIcon" alt="widget" /></div>
                                    <div><img src={networkFolder} className="connectorIcon networkFolder" alt="widget" /></div>
                                </div>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </div>
    );
}

export default Connectivity;