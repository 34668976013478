import './navbar.css';
import logo from '../../assets/bifrustLogo.svg';
import { Button, Dropdown, Grid, Icon, Input, Message } from 'semantic-ui-react';
import React, { useState } from 'react';
import authUser from '../auth/authUser';

function Navbar() {
    const [scrollState, setScrollState] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false); // New state for dropdown
    const { http } = authUser();

    const changeNavbarBG = () => {
        const scrollValue = document.documentElement.scrollTop;
        setScrollState(scrollValue > 60);
    };

    window.addEventListener('scroll', changeNavbarBG);

    const handleDropdownToggle = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && email && password) {
            handleSubmit(event);
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (email=='' && password==''){
            setErrorMsg('Email and Password could not be null.');
            return false;
        }
        else if (email==''){
            setErrorMsg('Email could not be null.');
            return false;
        }
        else if (password==''){
            setErrorMsg('Password could not be null.');
            return false;
        }
        console.log('handleSubmit ran');
        
        // Clear any previous error message
        setErrorMsg('');

        http.post('https://bifrust.com/api/login', { email, password })
            .then((res) => {
                if (!res.error) {
                    // Assuming successful login handling here...
                    const form = document.createElement('form');
                    form.method = 'POST';
                    form.action = 'https://bifrust.com/login'; 

                    const emailField = document.createElement('input');
                    emailField.type = 'hidden';
                    emailField.name = 'email';
                    emailField.value = email;  
                    form.appendChild(emailField);

                    const passwordField = document.createElement('input');
                    passwordField.type = 'hidden';
                    passwordField.name = 'password';
                    passwordField.value = password;  
                    form.appendChild(passwordField);

                    document.body.appendChild(form);
                    form.submit(); 

                } else {
                    setErrorMsg('These credentials do not match our records.');
                }
            })
            .catch((error) => {
                console.error('Login error:', error);
                setErrorMsg('These credentials do not match our records.');
            });
    };

    return (
        <div className="navbar">
            <header>
                <div className="nav">
                    <div className={scrollState ? "navScroll" : "nav"}>
                        <Grid verticalAlign="middle" centered columns={3}>
                            <Grid.Row className={scrollState ? "rowScroll" : ""} stretched>
                                <Grid.Column width={2}>
                                    <div className="navLogo">
                                        <img className={scrollState ? "navLogoImgScroll" : "navLogo"} src={logo} alt="logo" />
                                    </div>
                                </Grid.Column>
                                <Grid.Column verticalAlign="middle" width={8}>
                                    <ul className={scrollState ? "navULScroll" : ""}>
                                        {/* Navigation links */}
                                    </ul>
                                </Grid.Column>
                                <Grid.Column centered width={2}>
                                    <div className={scrollState ? "loginButtonScroll" : "loginButton"}>
                                        <Dropdown
                                            direction="left"
                                            icon="user"
                                            open={dropdownOpen} // Control dropdown open state
                                            onClick={handleDropdownToggle} // Toggle dropdown
                                        >
                                            <Dropdown.Menu className="loginDropDown" onClick={(e) => e.stopPropagation()}>
                                                <form onSubmit={handleSubmit} id="login-form" className="loginForm">
                                                    <Input
                                                        placeholder="Email"
                                                        id="email"
                                                        type="text"
                                                        icon="user"
                                                        onChange={(event) => setEmail(event.target.value)}
                                                        name="email"
                                                        onKeyDown={handleKeyPress}
                                                    />
                                                    <Input
                                                        placeholder="Password"
                                                        icon="key"
                                                        id="password"
                                                        type="password"
                                                        name="password"
                                                        onKeyDown={handleKeyPress}
                                                        onChange={(event) => setPassword(event.target.value)}
                                                    />
                                                    <p className="bottomLinks">{errorMsg}</p>
                                                    <Button className="submitButton" type="submit">
                                                        Login
                                                    </Button>
                                                </form>
                                                <div className='bottomLinks'>
                                                    <div>
                                                        <a style={{ color: "#ED3030"}} href="https://bifrust.com/password/reset">
                                                            Forgot password?
                                                        </a>
                                                    </div>
                                                    <div style={{ color: "#727070"}} >New Account</div>
                                                </div>
                                                

                                                {/* {errorMsg && (
                                                    <Message negative>
                                                        <p className="bottomLinks">{errorMsg}</p>
                                                    </Message>
                                                )} */}

                                                {/* <div className="bottomLinks">
                                                    <div>User registration will be available soon</div>
                                                </div> */}
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <div className="hamburgerButton">
                                            <Button>
                                                <Button.Content visible>
                                                    <Icon name="bars" />
                                                </Button.Content>
                                            </Button>
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Navbar;
